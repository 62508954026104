.custom-editor-insert-word-popup {
    position: absolute;
    z-index: 100;

    &_position_right {
        transform: translate(-32px, 20px);
    }

    &_position_left {
        transform: translate(calc(-100% + 32px), 20px);
    }

    &-content {
        width: 360px;
        display: flex;
        flex-direction: column;
        background: white;
        box-shadow: 0px 8px 32px rgba(20, 49, 127, 0.08);
        border: 1px solid #eef4fb;
        border-radius: 14px;
        padding: 12px 16px 16px 16px;
        position: relative;

        &::before {
            content: '';
            background: inherit;
            border: inherit;
            border-bottom: none;
            border-right: none;
            width: 26px;
            height: 26px;
            transform: rotate(45deg);
            position: absolute;
            top: -14px;
        }

        &_position_right::before {
            right: unset;
            left: 24px;
        }

        &_position_left::before {
            left: unset;
            right: 24px;
        }
    }

    * {
        box-sizing: border-box;
    }

    &-title {
        font-size: 15px;
        font-weight: 600;
        line-height: 20px;
        letter-spacing: 0em;
        color: var(--color-main-black);
        margin: 0 0 20px 0;
    }

    &-option-wrapper {
        margin: 0 0 12px 0;
    }

    &-enter-prompt {
        width: fit-content;
        font-size: 12px;
        font-weight: 400;
        line-height: 14px;
        letter-spacing: 0px;
        color: var(--color-grey-descriptor);
        margin-top: -12px;
    }

    &-buttons {
        display: flex;
        align-items: center;
    }

    &-save {
        width: 200px;
        margin-right: 12px;
    }

    &-cancel {
        border: 1px solid var(--color-gray-inactive);
        .button-text {
            color: var(--color-gray-inactive);
        }
    }
}
