.student-trainer-statistic{
    display: flex;
    flex-direction: column;
    min-width: 166px;
    height: fit-content;
    gap: 12px;
    padding: 12px 8px;
    border-radius: 14px;
    border: 1px solid var(--color-blue-border);
    background-color: var(--color-grey-background_1);

    &-text{
        display: flex;
        flex-direction: column;
        gap: 8px;
        align-items: center;
    }

    &-general-statistics{
        display: flex;
        flex-direction: row;
        gap: 4px;

        &-card{
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 4px;
            padding: 8px;
            border-radius: 8px;
            background-color: var(--color-gery-2);
            flex-grow: 1;
        }
    }

    &-last-ten-statistics{
        display: flex;
        flex-direction: column;
        gap: 8px;

        &-title{
            font-family: "Source Sans Pro", sans-serif;
            font-size: 10px;
            line-height: 12px;
            font-weight: 400;
            color: var(--color-grey-descriptor);
        }

        &-list{
            display: flex;
            flex-direction: row;
            gap: 2px;
        }

        &-item{
            height: 32px;
            flex-grow: 1;
            border-radius: 4px;
        }
    }
}
