.app-skeleton-card {
    width: 100% !important;

    &-container {
        & div {
            height: 100% !important;
        & span {
            height: 100% !important;
            width: 100% !important;
        }
      }
    }

    .app-skeleton-card-button {
        min-width: unset !important;
    }
}
