.app-datepicker {
    margin-top: 8px !important;
    display: flex !important;
    border-radius: 4px !important;
    border: 1px solid var(--color-stroke-maingray) !important;
    box-shadow: unset !important;
    height: 40px !important;
    padding-right: 4px !important;
    &-status-error{
        border: 1px solid var(--color-red-main) !important;
    }
    &-input > input {
        font-family: 'Source Sans Pro', sans-serif !important;
        font-size: 15px !important;
        color: var(--color-main-black) !important;
    }
    &-suffix {
        margin: unset !important;
        color: var(--color-main-black) !important;
    }
}

