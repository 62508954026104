.link-option {
    &-container {
        position: relative;
    }

    &-popup-container {
        position: absolute;
        top: 40px;
        left: -20px;
        z-index: 10;

        &_hidden {
            display: none;
        }
    }

    &-icon {
        path {
            fill: transparent;
            stroke: var(--color-main-black);
        }

        &_active {
            path {
                stroke: var(--color-link);
            }
        }
    }
}
