.attendanceLine {
    height: 58px;
    padding: 9px 42px;
    &:hover {
        background: #EEF4FB;
        box-sizing: border-box;
        border-radius: 14px;
        &>td:first-child {
            border-top-left-radius: 14px;
            border-bottom-left-radius: 14px;
        }
        &>td:last-child {
            border-top-right-radius: 14px;
            border-bottom-right-radius: 14px;
        }
    }
    &-avatar {
        padding: 13px 0 13px 13px;

        @media (max-width: 475px) {
            padding: 13px 0;
        }
    }
    &-presence {
        width: 200px;
        box-sizing: border-box;
        padding: 8px 0 10px;
    }
    &-avatar-link {
        text-decoration: none;
    }
}
