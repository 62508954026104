.question-info-modal{
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100%;

    &__wrapper {
        max-width: calc(100% - 300px);

        .ant-modal-content {
            padding: 24px;

            svg.app-ui-modal-close {
                top: 24px !important;
                right: 24px !important;
                width: 24px !important;
                height: 24px !important;
            }
        }
    }
    &__header {
        display: grid;
        grid-template-columns: repeat(4, auto);
        align-items: center;
        gap: 36px;
    }
    &__content {
        position: relative;
        display: flex;
        flex-direction: column;
        gap: 32px;

        &_divider {
            width: 100%;
            height: 1px;
            background-color: var(--color-stroke-maingray);
        }
        &_info {
            display: flex;
            flex-direction: column;
            gap: 24px;

            &-row {
                display: grid;
                grid-template-columns: 100px auto;
                gap: 16px;
            }
        }
        &_view{
            display: flex;
            flex-direction: column;
            gap: 10px;

            .question-body {
                font-size: 18px;
                line-height: 20px;
                color: var(--color-main-black);
            }
            .answers-list {

                &__answer-option {
                    display: flex;
                    align-items: center;
                    font-size: 18px;
                    gap: 5px;
                }
            }
        }
        &_buttons {
            display: grid;
            grid-template-columns: repeat(auto-fit, 200px);
            align-items: center;
            gap: 12px;
        }

    }
    &__button-left, &__button-right {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: 60px;
        height: 60px;
        border-radius: 50%;
        background-color: var(--color-essential);
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }
    &__button-left {
        left: -100px;
    }
    &__button-right {
        right: -100px;
    }
}
