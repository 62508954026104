.app-checkbox-indeterminate{
    .app-checkbox-inner {
        background-color: #1677ff;
        border: 1px solid #1677ff;

        &:after {
            width: 10px;
            height: 2px;
            background-color: #ffffff;
        }
    }
}
