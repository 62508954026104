.add-kinescope-video {
    display: flex;
    flex-direction: column;
    gap: 4px;

    &-wrapper{
        position: relative;
        width: 100%;
        border: 1px solid var(--color-stroke-maingray);
        border-radius: 12px;
        padding: 26px 32px 48px 30px;
        max-width: 1028px;
        background: var(--color-essential);

        &.error {
            border-color: var(--color-red-main);
        }
    }
    &__input {
        border: none !important;
        border-bottom: 1px solid var(--color-stroke-maingray)!important;
        border-radius: 0!important;
        padding-left: 0 !important;
        &.error {
            border-color: var(--color-red-main) !important;
        }
    }
    &__clear-btn {
        position: absolute;
        top: 32px;
        right: 24px;
        height: 40px;
        width: 40px;
        padding: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
    }
}
