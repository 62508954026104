@import 'katex/dist/katex.min.css';

.katex * {
    font-family: 'KaTeX_Main', 'Times New Roman',serif;
}

:root {
    --color-link: #0088e9;
    --color-blue: #007EEC;
    --color-blue-hover: #EEF4FB;
    --color-blue-border: #D3E0EF;
    --color-essential: #fff;
    --color-dashboard: #f9fafd;
    --color-nav-icon: #585c6e;
    --color-nav-text: #585c6e;
    --color-stroke-maingray: #eef4fb;
    --color-main-black: #252232;
    --color-red-main: #ea5959;
    --color-red-background: #F9CCCC;
    --color-text-color: #2c2d2e;
    --color-hover-blue: #3aa1eb;
    --color-gray-inactive: #c0c2c9;
    --color-gray-second: #edeef2;
    --color-grey-middle-grids: #68788d;
    --color-table-black: #323c47;
    --color-grey-descriptor: #919399;
    --color-secondary-text: #80878e;
    --color-main-dark-icons: #585c6f;
    --color-scroll-bar-background: #D1DDEA;
    --background-color: #EEF4FC;
    --color-grey-background_1: #F9FAFD;
    --color-gery-2: #EDEEF2;

    --black-shadow-color: #14317f14;

    //STYLED-NUMBER
    --styled-number-color-green-background: #d1e6e8;
    --styled-number-color-green-text: #2f929a;
    --styled-number-color-red-background: #f9cccc;
    --styled-number-color-red-text: #ea5959;

    //NOTIFICATION

    --notification-fill: #e6f2ee;
    --notification-text-color: #32946e;

    //BUTTON
    --button-border-radius: 4px;
    --button-size-l-text-indent-all: 10px 20px;
    --button-size-l-font-size: 15px;
    --button-size-l-height: 40px;
    --button-size-m-text-indent-all: 8px 40px;
    --button-size-m-font-size: 15px;
    --button-size-m-height: 36px;
    --button-size-s-text-indent-all: 5px 39px;
    --button-size-s-font-size: 12px;
    --button-size-s-height: 30px;
    --button-view-action-fill: #0088e9;
    --button-color-text: #fff;
    --button-view-action-typo-color-base: #fff;
    --button-view-bordered-typo-color-base: #0088e9;
    --button-view-bordered-fill: #fff;
    --button-border-default: #D3E0EF;
    --button-chat-fill: #0b76ef;

    --button-view-action-fill-disabled: #c0c2c9;
    --button-view-bordered-typo-color-base-disabled: #c0c2c9;
    --radio-button-border-radius: 4px;

    //SCHEDULE
    --schedule-date-item-text-color: #2c2d2e;
    --schedule-border-color: #d2e0ee;
    --schedule-date-item-selected-text-color: #fff;

    //USER PIC

    --user-pic-size-m-size: 48px;

    //DASHBOARD
    --dashboard-border-color: #eaeefa;
    --dashboard-border-color-red: #ff6883;
    --dashboard-table-header-text-color: #334d6e;

    --schedule-item-indicator-font-size: 13px;
    --schedule-item-date-font-size: 14px;
    --schedule-item-title-font-size: 15px;
    --schedule-item-theme-font-size: 13px;
    --schedule-item-indicator-before-text-color: #68788d;
    --schedule-item-indicator-before-border-color: #d1ddea;
    --schedule-item-indicator-before-fill: #d1ddea;
    --schedule-item-indicator-after-text-color: #4c5862;
    --schedule-item-indicator-after-border-color: #919399;
    --schedule-item-indicator-after-fill: #919399;
    --schedule-item-indicator-now-text-color: #4c5862;
    --schedule-item-indicator-now-border-color: #2f929a;
    --schedule-item-indicator-now-fill: #2f929a;
    --schedule-item-text-color: #68788d;
    --schedule-item-title-text-color: #252232;
    --schedule-item-theme-text-color: #585c6f;
    --schedule-date-item-text-color: #2c2d2e;
    --schedule-date-item-selected-text-color: #fff;
    --schedule-header-title-text-color: #2c2d2e;
    --schedule-header-subtitle-text-color: #919399;
    --schedule-icon-status-past-color: #c0c2c9;

    //example typography
    --font-size-large: 18px;
    --font-size-medium: 15px;
    --font-size-small: 13px;

    //marks
    --color-green-main: #2f929a;
    --color-green-background: #D1E6E8;
    --styled-number-color-yellow-background: #f9e9cc;
    --styled-number-color-yellow-text: #e5a139;
}
