.app-select{
    cursor: pointer;
    &-selector {
        height: 40px !important;
        padding-right: 30px !important;
    }
    &-selection {
        &-search-input {
            height: 38px !important;
            font-size: 15px !important;
            line-height: 20px !important;
        }
        &-item {
            font-size: 15px !important;
            line-height: 38px !important;
        }
        &-placeholder {
            font-size: 15px !important;
            line-height: 38px !important;
        }
    }
    &-arrow {
        path {
            fill: var(--color-main-black);
        }
    }
    &-open {
        .app-select-arrow {
            transform: rotate(180deg);
        }
    }

    &-sm .app-select{
        &-selector {
            height: 32px !important;
            padding-right: 30px !important;
        }
        &-selection {
            &-search-input {
                height: 38px !important;
                font-size: 15px !important;
                line-height: 20px !important;
            }
            &-item {
                font-size: 15px !important;
                line-height: 30px !important;
            }
            &-placeholder {
                font-size: 15px !important;
                line-height: 30px !important;
            }
        }
    }

    &_isBoldText .app-select{
        &-selection {
                &-item {
                font-weight: 600 !important;
            }
        }
    }
}

.app-select-item.app-select-item-option:last-of-type{
    //padding-bottom: 20px;
}
