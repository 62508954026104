.app-decline-accept-modal{
    &-close-x{
        display: flex !important;
        align-items: center;
        justify-content: center;
    }

    &-title{
        margin-top: 26px !important;
    }
    &-text{
        margin-top: 16px;
    }
    &-title,
    &-text{
        display: flex;
        width: 100%;
        justify-content: center;
        .app-text{
            text-align: center;
        }
    }

    &-buttons{
        display: flex;
        flex-direction: row;
        justify-content: center;
        width: 100%;
        gap: 16px;
        margin-top: 36px;
    }
}
