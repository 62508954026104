.inline-option {
    &-icon {
        &_type_BOLD {
            path {
                fill: var(--color-main-black);
            }
        }
        &_type_ITALIC {
            path {
                fill: var(--color-main-black);
            }
        }
        &_type_STRIKETHROUGH {
            path {
                fill: var(--color-main-black);
                &:nth-child(3n) {
                    fill: white;
                }
            }
            &:hover {
                path {
                    fill: var(--color-main-black);
                    &:nth-child(3n) {
                        fill: var(--color-gray-second);
                    }
                }
            }
        }
        &_type_UNDERLINE {
            path {
                fill: var(--color-main-black);
            }
        }

        &_active {
            path {
                fill: var(--color-link);
            }
        }

        &_type_STRIKETHROUGH.inline-option-icon_active {
            path {
                fill: var(--color-link);
                &:nth-child(3n) {
                    fill: var(--color-stroke-maingray);
                }
            }
        }
    }
}
