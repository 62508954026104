.lms-paginate-select{
    &-selector, &-selection-item{
        border-radius: 4px !important;
    }
    &-item {
        &:last-of-type {
            padding-bottom: 20px;
        }
    }
    &-selection-item{
        background: var(--color-stroke-maingray) !important;
        border: 1px solid var(--color-stroke-maingray) !important;
        svg {
            fill: #0088E9;
        }
    }
    .anticon-loading svg path {
        fill: var(--color-link);
    }
}
