.question-search-bar {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    padding: 30px 20px 20px;
    gap: 12px;

    &__content {
        display: flex;
        flex-direction: column;
        gap: 24px;

        &_filters {
            display: flex;
            flex-direction: column;
            gap: 16px;

            &-subject {
                display: flex;
                flex-direction: column;
                gap: 8px;
            }
            .AppTextStyle.DesktopFontBody {
                line-height: unset;
            }
            .ant-select-tree .ant-select-tree-checkbox {
                top: 5px;
                margin-inline-end: 8px;
                align-self: baseline;
            }
            .tree-selector__popup {
                right: 0 !important;
                left: 100% !important;

                &.down{
                    top: 0 !important;
                }
                &.up {
                    top: unset !important;
                    bottom: 0 !important;
                }
            }
        }
    }
    &__controls {
        display: flex;
        align-items: center;
        flex-direction: column;
        gap: 12px;
        width: 100%;

        &_create {
            cursor: pointer;
        }
        &_save {
            display: flex;
            gap: 12px;
            width: 100%;
        }
        &_delete {
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            min-width: 40px;
            height: 40px;
            border-radius: 4px;
            border: 1px solid var(--color-red-main);

            &:hover {
                background-color: var(--color-dashboard);
            }
        }
    }
}
