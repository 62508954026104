.propertyMenu {
    width: 100%;

    &-title {
        font-size: 24px;
        line-height: 28px;
        font-weight: 600;
        margin-bottom: 18px;
    }

    &-header {
        display: flex;
        justify-content: space-between;
    }

    &-save {
        width: 200px;
        margin-right: 12px;
        &_disabled {
            background-color: var(--color-gray-inactive);
            &:hover {
                cursor: inherit;
                background-color: var(--color-gray-inactive);
            }
        }
    }

    &-cancel {
        width: 132px !important ;
    }

    &-history{
        margin-right: 12px;
        cursor: pointer;
    }

    &-delete {
        margin-left: 12px;
        width: 40px !important;
        padding: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border: 1px var(--color-dashboard) !important;

        .button_size_l {
            padding: 0 !important;
        }

        svg {
            path {
                fill: none !important;
            }
        }

        &:hover {
            border: 1px solid var(--color-red-main) !important;
        }

        .button-text_with-icon {
            width: auto;
            justify-content: center;
        }
    }

    &-buttons {
        display: flex;
    }

    &-modal{
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: rgba(39,39,39,.1);
        z-index: 2;
    }

    &-del{
        position: absolute;
        top:22px;
        right: 22px;
        cursor: pointer;
    }

    &-window{
        position: relative;
        width: 62vw;
        height: 53vh;
        background-color: var(--color-essential);
        border-radius: 20px;
        display: flex;
        flex-direction: column;
    }

    &-titl{
        margin-top: 32px;
        margin-bottom: 36px;
        font-size: 18px;
        font-weight: 600;
        text-align: center;
    }

    &-flex-title{
        display: flex;
        font-size: 15px;
        font-weight: 600;
        justify-content: space-around;
        margin-bottom: 10px;
        margin-right: 8px;
    }

    &-flex{
        display: flex;
        font-size: 13px;
        justify-content: space-around;
        margin-top: 8px;
        margin-bottom: 8px;
    }

    &-flex-container{
        display: flex;
        flex-direction: column;
        overflow-y: auto;
        margin: 0 8px 12px 0;
    }

    &-flex-date{
        width: 15%;
        text-align: center;
    }

    &-flex-fio{
        width: 28%;
        text-align: center;
    }

    #scroll-bar::-webkit-scrollbar {
        width: 4px;
        background-color: var(--color-stroke-maingray);
        border-radius: 40px;
    }

    #scroll-bar::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background-color: var(--schedule-item-indicator-before-fill);
        border-radius: 40px;
    }

    #scroll-bar::-webkit-scrollbar-track {
        background-color: var(--color-stroke-maingray);
        border-radius: 40px;
    }

    &-row {
        display: grid;
        grid-template-columns: 97px 1fr;
        grid-column-gap: 12px;
        margin-bottom: 16px;
        align-items: center;
    }

    &-toggle {
        display: flex;
        align-items: center;
    }

    &-name {
        font-size: 15px;
        line-height: 37px;
        align-self: baseline;
    }

    &-location {
        width: 61%;
        display: flex;
        flex-direction: column;
        row-gap: 16px;

        &-subject-dropdown {
            width: 92%;
        }
    }

    &-complexity {
        width: 288px;
    }

    &-note {
        font-size: 12px;
        line-height: 20px;
        color: #919399;
        margin-left: 8px;
    }

    &-file-container {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        gap: 12px;
        width: calc(100% - 110px);
        max-width: 608px;
        min-height: 40px;
    }

    &-file-input-label {
        background-color: var(--color-link);
        cursor: pointer;
        width: 200px;
        min-height: 40px;
        border-radius: 6px;
        display: flex;
        align-items: center;
        justify-content: center;

        font-size: 15px;
        font-weight: 400;
        line-height: 20px;
        color: var(--color-essential);

        &:hover {
            background-color: var(--color-hover-blue);
        }

        input {
            display: none;
        }
    }

    @keyframes rot {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }

    &-loading-icon {
        display: flex;
        width: 100%;
        height: 100%;
        justify-content: center;
        align-content: center;
        animation: 1.5s linear 0s normal none infinite running rot;
    }

    &-upload-progress {
        flex-grow: 1;
        background-color: var(--color-essential);
        border: 1px solid var(--color-stroke-maingray);
        height: 6px;
        border-radius: 3px;

        &::-webkit-progress-bar {
            background-color: var(--color-stroke-maingray);
            height: 6px;
            border-radius: 3px;
        }

        &::-webkit-progress-value {
            background-color: var(--color-link);
            border-radius: 3px;
        }

        &::-moz-progress-bar {
            background-color: var(--color-link);
            border-radius: 3px;
        }
    }

    &-input-container {
        display: flex;
        align-items: center;
        position: relative;
        background-color: var(--color-essential);
        border: 1px solid var(--color-stroke-maingray);
        border-radius: 6px;
        padding: 0 12px;
        min-height: 40px;
        flex: 1 1 auto;
    }

    &-input {
        flex: 1 1 auto;
        min-height: 40px;
        outline: none;
        border: none;
        font-weight: 400;
        font-size: 15px;
        line-height: 20px;
        background-color: var(--color-essential);
        color: var(--color-main-black);
        padding: 0;

        &::placeholder {
            color: var(--color-grey-descriptor);
        }
    }

    &-icon {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 5px;

        path {
            fill: var(--color-link);
        }

        circle {
            stroke: var(--color-link);
        }
        &_copy {
            cursor: pointer;
            path {
                fill: var(--color-essential);
            }

            &:hover {
                path {
                    stroke: var(--color-hover-blue);
                }
            }
        }

        &:hover {
            circle {
                stroke: var(--color-hover-blue);
            }
        }
    }

    &-tooltip {
        display: flex;
        position: fixed;
        justify-content: center;
        width: 164px;
        z-index: 9999;

        transform: translate(calc(-100% + 16px), calc(16px));

        &-pointer {
            position: absolute;
            width: 24px;
            height: 24px;
            transform: rotate(45deg);
            background-color: var(--color-essential);
            border-top: 1px solid var(--color-stroke-maingray);
            border-left: 1px solid var(--color-stroke-maingray);
            top: 30px;
            z-index: 10000;
        }
    }
}
