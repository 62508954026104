.empty-data {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
    height: 100%;
    position: relative;

    img {
        max-height: 80%;
    }
}
