.multiple-selector{
    width: 100%;

    .ant-select {
        width: 100%;
        cursor: pointer;

        &-selection-item {
            height: 32px;
            align-items: center;
            margin-top: 0;
        }
        &-arrow {
            path {
                fill: var(--button-view-action-fill);
            }
        }
        &-selection-overflow{
            flex-wrap: nowrap;

            &-item-rest .ant-select-selection-item {
                height: unset;
                margin: 0 !important;
                padding: 0;
                background: none;
            }
        }
    }
    &__tag{
        font-size: 15px;
        font-family: "Source Sans Pro", sans-serif;
        height: 30px;
        display: flex;
        align-items: center;
        background: var(--color-stroke-maingray);
        border: none;

        &.hidden {
            display: none;
        }
        &.no-background {
            background: none;
        }
        .user-avatar {
            display: none;
        }
    }
    &__dropdown {
        &.withCheckboxes .ant-select-item-option {
            flex-direction: row-reverse;
            gap: 8px;
        }

        .app-ui-input {
            margin: 10px;

            .ant-input {
                padding: 5px 38px 5px 10px;
            }
            svg.input-icon.cross {
                top: 2px !important;
            }
        }
    }


}
