.user-avatar {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    &__wrapper {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;

        &_online {
            position: absolute;
            content: '';
            top: -2px;
            right: -2px;
            width: 13px;
            height: 13px;
            border: 2px solid var(--color-essential);
            border-radius: 50%;
            background-color: var(--styled-number-color-green-text);
        }
    }

}
