@mixin no-bg {
    background: none;
    outline: none;
    border: none;
}

@mixin center-vertical {
    display: flex;
    align-items: center;
}

.multiple-question {
    &-answer-option {
        &:hover,
        &:focus,
        &:active,
        &:focus-within {
            .multiple-question-btn-group {
                opacity: 1;
            }
        }
    }

    &-checkbox-wrapper {
        align-self: center;
        &_isEditor {
            align-self: flex-start;
            margin-top: 20px;
        }
    }

    &-btn-group {
        @include center-vertical();
        margin-right: 24px;
        opacity: 0;
        transition: 200ms;
        align-self: flex-end;
        // half the (input height - buttons height)
        margin-bottom: calc(((1em + 1em + 20px) - 27px) / 2);

        button {
            @include no-bg();
            cursor: pointer;
        }
    }

    &-delete svg path {
        stroke: var(--button-view-action-fill);
    }
}
