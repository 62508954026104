.warning-sign {
    padding: 7px 8px;
    border: 1px solid;
    border-radius: 8px;
    display: grid;
    grid-template-columns: auto 1fr;
    align-items: center;
    gap: 4px;

    &.warning {
        border-color: var(--styled-number-color-yellow-text);
        background-color: var(--styled-number-color-yellow-background);
    }
    &.error {
        border-color: var(--color-red-main);
        background-color: var(--styled-number-color-red-background);
    }
}
