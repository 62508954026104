.homeworkLine {
    padding-left: 8px;

    &_blocked {
        padding-left: 0;
    }
}
.homeworkLine_blocked label.checkbox.checkbox_isDisabled {
    padding-left: 8px;
}
