.block-option {
    &-icon {
        width: 32px;
        height: 32px;

        &_type {
            &_ordered-list-item {
                path {
                    stroke: var(--color-main-black);
                }
            }
            &_unordered-list-item {
                path {
                    fill: var(--color-main-black);
                    &:nth-child(odd) {
                        stroke: var(--color-main-black);
                    }
                }
            }
            &_ordered-list-item.block-option-icon_active {
                path {
                    stroke: var(--color-link);
                }
            }
            &_unordered-list-item.block-option-icon_active {
                path {
                    fill: var(--color-link);
                    &:nth-child(odd) {
                        stroke: var(--color-link);
                    }
                }
            }
        }
    }
}
