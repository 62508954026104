.charts-legend-item {
    display: flex;
    align-items: center;
    gap: 8px;

    &__colored-block {
        display: block;
        width: 24px;
        height: 6px;
        border-radius: 2px;
    }
}
