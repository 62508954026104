.code-block-component {
    border-radius: 14px !important;
    background: var(--color-dashboard);
    border-color: var(--color-gray-second);
    border-style: solid;
    padding: 10px 48px 10px 30px;

    li {
        margin-left: 0 !important;
        padding-left: 12px;

        &::marker {
            content: counter(list-item);
            color: var(--color-gray-inactive) !important;
            margin-right: 12px !important;
        }
    }
}
