.link-wrapper {
    position: relative;

    a {
        color: #009aec;
    }

    :hover {
        a {
            color: #3aa1eb;
        }
    }

    &-popup {
        position: fixed;
        top: 50%;
        transform: translateY(-50%);
        z-index: 100;
        width: 32px;
        height: 32px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid var(--color-stroke-maingray);
        border-radius: 6px;
        background: white;
        box-shadow: 0 8px 32px 0 rgba(20, 49, 127, 0.08);
        cursor: pointer;

        &:hover {
            background: var(--color-gray-second);
        }

        svg,
        svg path {
            fill: transparent !important;
            stroke: var(--color-link) !important;
        }
    }
}
