.insert-word-exercise {
    &-input{
        width: auto;
        max-height: 32px;

        &_correctness {
            &_incorrect {
                border-color: var(--color-red-main) !important;
                background-color: var(--color-red-background) !important;
                color: var(--color-main-black) !important;
            }

            &_correct {
                border-color: var(--color-green-main) !important;
                background-color: var(--color-green-background) !important;
                color: var(--color-main-black) !important;
            }
        }
    }

    &-text{
        font-family: "Source Sans Pro", sans-serif;
        font-size: 15px;
        font-weight: 600;
        line-height: 40px;
        margin: 0;
    }
}
