.app-tree-select{
    &__dropdown{

        &_header{
            display: flex;
            justify-content: space-between;
            align-items: baseline;
            max-width: 100%;
            gap: 15px;
            padding: 5px 12px;

            .arrow-icon{
                transform: rotate(-90deg) translateY(3px);
                transition: all .3s ease;

                svg {
                    height: 12px;
                    path{
                        stroke: var(--schedule-item-title-text-color);
                    }
                }
            }
        }
        &-list {
            display: flex;
            flex-direction: column;
            visibility: hidden;
            height: 0;

            &_item {
                padding-bottom: 5px;

                &.empty {
                    .app-text {
                        color: var(--color-grey-descriptor);
                    }
                }
                &.selected {
                    background-color: var(--color-hover-blue);
                }
            }
        }

        &.opened {
            .ant-select-item-option-content {
                display: flex;
                flex-direction: column;
            }

            .arrow-icon{
                transform: rotate(90deg);
            }
            .app-tree-select__dropdown-list {
                visibility: visible;
                height: auto;
                padding: 0 12px;
            }
        }
        .ant-select-item-option-content{
            white-space: normal;
        }
    }
    &.ant-select-selector, &.ant-select-selection-item{
        border-radius: 4px !important;
    }
    span.ant-tag {
        height: 32px;
        font-size: 16px;
        display: flex;
        align-items: center;
        background: var(--color-stroke-maingray);
        border: 1px solid var(--color-stroke-maingray);
        margin-top: 2px;
        margin-bottom: 2px;
        line-height: 30px;
        font-family: "Source Sans Pro", sans-serif;

        svg {
            fill: #0088E9;
        }
    }

    .rc-virtual-list-holder-inner {
        gap: 2px;
    }
}
.ant-select-item.ant-select-item-option.app-tree-select__dropdown {
    padding: 0;

    &:last-of-type {
        padding-bottom: 15px;
    }
}
