.schedule-card {
    &__wrapper {
        position: relative;
        display: flex;
        flex-direction: column;
        padding: 12px;
        background: #FFFFFF;
        border: 1px solid #0088E9;
        border-radius: 14px;
        cursor: pointer;
        animation: toBack 100ms linear backwards;
        height: calc(100% - 4px);

    }

    &:hover, &.selected {
        z-index: 1000 !important;

        .schedule-card__tooltip-overlay {
            z-index: 999;
        }
        .schedule-card__wrapper{
            z-index: 1000 !important;
            animation: toFront 100ms linear forwards;
            box-shadow: 0 8px 32px 0 #14317f14;
        }
    }
}
@keyframes toFront {
    from { transform: scale(1); }
    to { transform: scale(1.015)}
}

@keyframes toBack {
    from { transform: scale(1.015); }
    to { transform: scale(1); }
}
