.lesson-popup {
    &__breadcrumbs {
        display: grid;
        grid-template-columns: auto auto 1fr;
        align-items: center;
        gap: 16px;

        .app-text {
            color: var(--color-grey-descriptor);
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
    }
    &__container {
        display: flex;
        flex-direction: column;
        gap: 24px;

        &-skeleton{
            display: grid;
            grid-template-columns: 1fr;
            grid-template-rows: auto 1fr;
            gap: 24px;
            height: 100%;
        }

        &_lessons-paginate {
            display: grid;
            grid-template-columns: auto 1fr auto;
            align-items: center;
            gap: 20px;

            .btn {
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;

                svg path {
                    stroke: var(--button-view-action-fill);
                }

                &.disabled {
                    cursor: unset;
                    svg path {
                        stroke: var(--color-grey-descriptor);
                    }
                }

                &-prev svg {
                    rotate: -180deg;
                }
            }
            &-current-lesson {
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                text-align: center;
            }
        }

        .ant-steps-item-container {
            display: grid;
            grid-template-columns: auto 1fr;
            margin-bottom: 20px;

            @media (max-width: 475px) {
                margin-bottom: unset;
            }

        }
        @media (max-width: 475px) {
            .ant-steps-item:last-of-type:not(:has(.materialsTab-fields)) .ant-steps-icon:before {
                content: '';
                height: 10px;
                width: 1px;
                display: block;
                position: absolute;
                top: -15px;
                left: 0;
                border-left: 1px dashed var(--button-view-action-fill-disabled);
            }
        }

        .ant-tabs .ant-tabs-content {
            height: 100%;
            min-height: 50vh;
        }

    }
    .ant-drawer-content-wrapper {
        width: 100% !important;
    }
    .ant-drawer-header{
        border-bottom: none;

        &-title {
            display: flex;
            flex-direction: row-reverse;
        }
        .ant-drawer-close {
            margin: 0;
            padding: 0;
        }
    }
}
