.lesson-info {
    margin-top: 12px;

    &__container {
        display: flex;
        flex-direction: column;
        gap: 8px;

        &_item {
            display: flex;
            align-items: center;
            gap: 12px;

            @media (max-width: 768px) {
                gap: 4px
            }

            .time-container {
                display: flex;
                align-items: center;
                gap: 4px;
                @media (max-width: 768px) {
                    gap: 2px
                }

                &__item {
                    display: flex;
                    align-items: center;
                    gap: 2px;
                    @media (max-width: 768px) {
                        gap: 0;
                    }
                    white-space: nowrap;
                }
            }
        }
    }
}
