.editor {
    background: white;
    font-size: 16px;
    line-height: 20px;
    border-radius: 8px;
    padding: 0 16px;
    border: 1px solid white;
    overflow: visible;

    &_needLimitHeight {
        & .DraftEditor-editorContainer {
            overflow: auto visible;
            max-height: 260px;

            &::-webkit-scrollbar {
                width: 4px;
                background-color: var(--color-stroke-maingray);
                border-radius: 2px;
                position: absolute;
                right: 0;
            }

            &::-webkit-scrollbar-thumb {
                background-color: var(--color-scroll-bar-background);
                border-radius: 2px;
            }
        }
    }

    ol {
        padding-left: 16px;
    }

    &_isError {
        border: 1px solid #ea5959;
    }

    &-toolbar {
        padding: 0 42px 0 0;
        border: none;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: wrap;
        gap: 12px;
        min-height: 40px;
        background: none;
        user-select: none;
        margin-bottom: 20px;

        &_toolbarHidden {
            display: none;
        }
    }

    .katex * {
        font-family: 'KaTeX_Main', 'Times New Roman', serif;
    }
}
