.files-container {
    display: flex;
    gap: 30px;
    width: 100%;

    &_isTest {
        width: 240px;
    }

    &_student_view {
        display: block;

        .file-name {
            max-width: 200px;
        }
    }

    &-filesTitle {
        font-weight: 600;
        font-size: 24px;
        line-height: 28px;
        color: var(--color-main-black);
    }

    &-files {

        @media screen and (max-width: 920px) {
            flex-basis: 154px;
        }
    }

    &-filesIsTemplateTitle {
        font-size: 15px;
        line-height: 16px;
        color: #65788d;
        padding-top: 5px;
        margin-bottom: 54px;
        height: 40px;
        width: min-content;
    }

    &-filesUseOnConference,
    &-filesAvailability,
    &-filesIsTemplate {

        @media screen and (max-width: 920px) {
            flex-basis: 132px;
        }
    }

    &-filesUseOnConferenceTitle,
    &-filesAvailabilityTitle {
        font-size: 15px;
        line-height: 16px;
        color: #65788d;
        padding-top: 5px;
        height: 40px;
        width: 120px;
        margin-bottom: 54px;

        @media screen and (max-width: 920px) {
            width: 112px;
        }
    }

    &-filesAvailabilityCheckbox,
    &-filesUseOnConferenceCheckbox,
    &-filesIsTemplateCheckbox {
        margin-top: 25.5px;
        height: 33px;
        display: flex;
        align-items: center;
    }

    .file-name {
        overflow: hidden;
        max-width: unset;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
}
