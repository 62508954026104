.subject-element {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    &-container {
        padding: 4px;
        padding-left: 12px;
        display: flex;
        flex-direction: row;
        align-items: center;
        width: fit-content;

        &_selected {
            width: 204px;
            border-radius: 6px;
            background-color: var(--color-stroke-maingray);
        }
    }

    &-header {
        display: flex;
        cursor: pointer;

        &_selected {
            color: var(--color-link);
            font-weight: 600;
        }

        &_disabled {
            cursor: default !important;
            color: var(--color-gray-inactive);
        }
    }

    &-open {
        transform: rotate(270deg);
        height: 24px;
        display: flex;
        align-items: center;

        svg {
            path {
                fill: var(--color-grey-middle-grids);
            }
        }

        &_opened {
            transform: rotate(0deg);
            svg {
                path {
                    fill: var(--color-link) !important;
                }
            }
        }

        &_disabled {
            path {
                fill: var(--color-gray-inactive);
            }
        }
    }

    &-title {
        margin-left: 8px;
        font-size: 17px;
        line-height: 24px;
        font-weight: 600;
    }

    &-elements-container {
        margin-left: 16px;
    }
}