.app-button{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 9px 20px;
    border-radius: 6px;
    height: 40px;

    &-body{
        display: flex;
        flex-direction: row;
        justify-content: center;
        gap: 9px;

        &_isLoading{
            opacity: 0;
        }
    }

    &_isNoText{
        padding: 9px;
        min-width: 40px;
    }
    .blue{
        path {
            stroke: var(--color-link);
        }
    }
    .red{
        path {
            stroke: var(--color-red-main);
        }
    }
    .grey{
        path {
            stroke: var(--color-gray-inactive);
        }
    }
    .white{
        path {
            stroke: var(--color-essential);
        }
    }

    @-webkit-keyframes rotating /* Safari and Chrome */ {
        from {
            -webkit-transform: rotate(0deg);
            -o-transform: rotate(0deg);
            transform: rotate(0deg);
        }
        to {
            -webkit-transform: rotate(360deg);
            -o-transform: rotate(360deg);
            transform: rotate(360deg);
        }
    }
    @keyframes rotating {
        from {
            -ms-transform: rotate(0deg);
            -moz-transform: rotate(0deg);
            -webkit-transform: rotate(0deg);
            -o-transform: rotate(0deg);
            transform: rotate(0deg);
        }
        to {
            -ms-transform: rotate(360deg);
            -moz-transform: rotate(360deg);
            -webkit-transform: rotate(360deg);
            -o-transform: rotate(360deg);
            transform: rotate(360deg);
        }
    }
    .rotating {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        width: 20px;
        height: 20px;
        -webkit-animation: rotating 2s linear infinite;
        -moz-animation: rotating 2s linear infinite;
        -ms-animation: rotating 2s linear infinite;
        -o-animation: rotating 2s linear infinite;
        animation: rotating 2s linear infinite;
    }


    &-elem{
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        &_isDisabled{
            path {
                stroke: var(--color-gray-inactive);
            }
        }
    }
}
