.tag {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    padding: 1px 6px;
    width: fit-content;
    max-height: 30px;
    white-space: nowrap;

    .app-text {
        font-size: 10px;
        color: inherit !important;
    }

    &-online_lesson {
        background-color: var(--color-stroke-maingray);
        color: var(--button-view-action-fill);
    }
    &-test_lesson {
        background-color: var(--styled-number-color-green-background);
        color: var(--schedule-item-indicator-now-border-color);
    }
    &-selfpreparation_lesson {
        background-color: var(--styled-number-color-yellow-background);
        color: var(--styled-number-color-yellow-text);
    }
}
