.search-questions-popup {

    &__title {
        display: grid;
        grid-template-columns: max-content minmax(auto, 700px);
        align-items: center;
        gap: 30px;

        &_breadcrumbs {
                display: flex;
                align-items: baseline;
                gap: 0 16px;
                flex-wrap: wrap;
        }
    }

    &__container{
        display: grid;
        grid-template-columns: 300px 1fr;
    }
    .ant-drawer-content-wrapper {
        width: 100% !important;
    }
    .ant-drawer-header{
        border-bottom: none;

        &-title {
            display: flex;
            flex-direction: row-reverse;
        }
        .ant-drawer-close {
            margin: 0;
            padding: 0;
        }
    }
}
