.progress-bar-advanced {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    width: 100%;
    height: 24px;

    &.dashed {
        gap: 1px;
    }

    &__popover {
        display: flex;
        flex-direction: column;
        gap: 4px;
        min-width: 175px;
        width: auto;

        &_row {
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 10px;
        }
    }
    &__cell {
        display: flex;
        flex-direction: column;
        width: 100%;
        gap: 2px;
        cursor: pointer;

        &_deg{
            display: block;
            width: 100%;
            border-radius: 2px;
            height: 4px;
            background-color: initial;

            &:hover {
                height: 6px;
                box-shadow: 0 0 24px 1px;
            }

            &.red {
                color: var(--color-red-main);
                background-color: var(--color-red-main);
            }
            &.yellow {
                color: var(--styled-number-color-yellow-text);
                background-color: var(--styled-number-color-yellow-text);
            }
            &.blue {
                color: var(--button-view-action-fill);
                background-color: var(--button-view-action-fill);
            }
            &.grey {
                color: var(--button-border-default);
                background-color: var(--button-border-default);
            }

        }
    }
}
