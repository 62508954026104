.file {
    height: 33px;
    display: grid;
    grid-template-columns: auto 90px;
    margin-top: 25.5px;
    max-width: 500px;

    &-main {
        display: flex;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    &-name {
        font-size: 13px;
        color: #252232;
        margin: auto 0 auto 9px;
        text-decoration: none;
        max-width: 140px;
        white-space: nowrap;
    }

    &-hoverIcons {
        display: flex;
    }

    button {
        padding: 0;
        border: none;
        background: none;
        height: 33px;
        width: 33px;
        cursor: pointer;
        outline: none;
    }

    button:hover {
        background-color: #f9fafd;
        border: none;
        border-radius: 4px;
    }

    &-send {
        margin-left: 12px;
    }

    &-copy {
        margin-left: 12px;
    }

    &-basket {
        margin-left: 12px;
    }

    &-edit {
        margin-left: 12px;
    }
}
