.alert-modal {
    display: flex;
    flex-direction: column;
    gap: 20px;
    border-radius: 15px;
    padding: 10px 20px;
    overflow: hidden;

    &__header{
        display: flex;
        align-items: center;
        justify-content: space-between;

        &_close-btn {
            cursor: pointer;
            height: 25px;

            path {
                stroke: var(--button-view-action-fill);
            }
        }
        .app-text {
            color: var(--button-view-action-fill);
        }
    }
    &__list {
        position: relative;
        display: flex;
        flex-direction: column;
        gap: 10px;
        overflow-y: auto;
        overflow-x: hidden;
        height: 300px;
        padding-right: 6px;

        scrollbar-width: thin;
        scrollbar-color: var(--schedule-item-indicator-before-border-color);

        /* Работает в Chrome, Edge и Safari */
        &::-webkit-scrollbar {
            width: 4px;
        }

        &::-webkit-scrollbar-thumb {
            background-color: var(--schedule-item-indicator-before-border-color);
            border-radius: 40px;
        }

        &_alert {
            display: grid;
            grid-template-columns: 1fr 120px;
            align-items: center;
            grid-column-gap: 12px;
            border-radius: 7px;
            padding: 20px 24px;
            background: var(--color-essential);
            border: 1px solid var(--button-view-action-fill);

            &-text {
                display:grid;
                grid-template-columns: 1fr;
                grid-row-gap: 8px;

                .app-expandable-text {
                    display: grid;
                    grid-row-gap: 12px;
                }
            }
        }
        &-loader{
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            background: #ffffffd9;
        }
    }
}
