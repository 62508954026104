.subject-course-card {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 12px;
    border-radius: 8px;
    min-height: 60px;
    cursor: pointer;

    &:hover, &.selected {
        .additional-title{
            visibility: visible;
        }
    }
    &.lesson-card {
        padding: 20px 24px;

        @media (max-width: 1024px) {
            &.border-gray {
                border-width: 2px;
            }
        }
        @media (max-width: 768px) {
            padding: 20px 12px;
        }
    }
    &.border {
        &-none {
            border: 2px solid transparent;
        }
        &-blue {
            border: 2px solid var(--button-view-action-fill);
        }
        &-gray{
            border: 2px solid var(--button-border-default);

            @media (max-width: 1024px) {
                border-width: 1px;
            }
        }
    }
    &.dim {
        opacity: 0.2;
    }
    &.light {
        background-color: var(--color-essential);

        &.hovered:hover, &.hovered.selected {
            background-color: var(--color-stroke-maingray);
            border-color: transparent;
        }
    }
    &.dark {
        background-color: var(--color-stroke-maingray);

        &.hovered:hover, &.hovered.selected {
            background-color: var(--color-essential);
            border-color: transparent;
        }
    }

    .divider {
        display: block;
        width: 100%;
        height: 1px;
        background-color: var(--button-border-default);
    }
    &__title {
        display: grid;
        grid-template-columns: 1fr auto;
        align-items: flex-start;
        gap: 5px;

        &.one-line>.app-text{
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
        &.multiple>.app-text {
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
        }
        .additional-title {
            visibility: hidden;

            @media (max-width: 768px) {
                visibility: visible;
            }
        }
        &_edit-btn {
            cursor: pointer;
        }
    }
    &__metrics{
        display: flex;
        flex-direction: column;
        gap: 12px;

        &_list {
            display: flex;
            flex-direction: column;
            gap: 2px;
            width: max-content;

            &-item {
                display: flex;
                padding: 3px 8px;
                width: auto;
                border: 1px solid transparent;
                border-radius: 4px;

                &.common {
                    color: var(--color-grey-descriptor);
                }
                &.critical {
                    color: var(--color-red-main);
                    border-color: var(--color-red-main);
                }
            }
        }
    }
    &__mobile-content {
        margin-top: 12px;
        background-color: var(--color-essential);
        border-radius: 8px;
        border: 1px solid var(--button-border-default);
        overflow: hidden;
    }
}
