.materialsTab {
    height: 50vh;
    padding: 20px 0;

    .button {
        width: 210px;
    }

    &-text {
        width: 422px;
        display: flex;
        justify-content: space-between;
    }

    &-block-button {
        width: 210px;
        .button-text {
            display: flex;
            align-items: center;
            justify-content: space-around;
        }
        .button_view_bordered {
            border: 1px solid var(--color-red-main);
            svg {
                path {
                    fill: var(--color-red-main);
                }
            }
            .button-text {
                color: var(--color-red-main);
            }
            &:hover {
                background-color: var(--color-red-main);
                border-color: var(--color-red-main);
                svg {
                    path {
                        fill: var(--color-essential);
                    }
                }
                .button-text {
                    color: var(--color-essential);
                }
            }
        }

        .button_view_danger {
            border-radius: 4px;
            .button-text {
                color: var(--color-essential);
            }
            svg {
                path {
                    fill: var(--color-essential);
                }
            }
            &:hover {
                border: 1px solid var(--color-red-main);
                background-color: transparent;
                .button-text {
                    color: var(--color-red-main);
                }
                svg {
                    path {
                        fill: var(--color-red-main);
                    }
                }
            }
        }
    }

    &-materials {
        padding-bottom: 40px;

        &:has(.ant-steps-item-content) {
            display: grid;
            grid-template-columns: 1fr;
        }

        &-title {
            padding: 0;
            margin: 0;
            font-size: 15px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
            letter-spacing: 0px;
            text-align: left;
            color: var(--color-link);
        }
        &__progress-bar {
            margin-top: 50px;
        }
        .task-text-info {
            .app-text {
                color: var(--color-gray-inactive);
            }

            @media (max-width: 475px) {
                text-align: center;
            }
        }
    }

    .expanded-video-material_showForStudents,
    .expanded-audio-material_showForStudents {
        margin-left: unset;
        padding: 0;

    }

    .expanded-audio-material-track-line {
        width: 70%;
    }

    .expanded-audio-material-input-range {
        width: 100%;
    }

    .expanded-video-material-video {
        width: 100%;
        max-width: 471px;

        @media screen and (max-width: 640px) {
            height: calc(100vw * 0.4);
        }
    }

    .expanded-video-material-icon,
    .expanded-audio-material-icon {
        @media screen and (max-width: 840px) {
            margin-left: 8px;
        }
    }

    &-empty {
        background-image: url('../../../../../../assets/images/empty-subject.png');
        background-repeat: no-repeat;
        background-position: center top;
        max-height: 700px;
        height: calc(100vh - 224px);
        width: 100%;
        background-size: contain;

        &-title {
            color: var(--color-text-color);
            font-size: 24px;
            line-height: 28px;
            font-weight: 600;
        }

        &-button {
            width: 422px;
            display: flex;
            justify-content: space-between;
            .button {
                width: 200px;
            }
        }
    }

    &-materials-title-wrapper {
        display: flex;
        align-items: center;
    }

    &-schedule-icon {
        margin: 0 10px;

        path,
        rect {
            stroke: var(--color-link);
        }
    }

    &-date {
        padding: 3px 0 0;
        margin: 0;
        font-size: 13px;
        line-height: 16px;
        color: var(--color-link);
    }

    .expanded-image-material-description {
        font-style: italic;
    }

    .expanded-material-text {
        margin-top: 20px;

        & p {
            font-size: 15px;
            margin: 1em 0;
        }
    }

    .expanded-image-material {
        &-row-item {
            margin-bottom: 12px;
        }
        &-image-item > img {
            max-width: 100%;
        }
    }

    .expanded-material-table_student > .expanded-material-table-header {
        font-family: 'Source Sans Pro', sans-serif !important;
        font-size: 18px;
        line-height: 24px;
        margin: 0;
    }
    .block-number {
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        border: 1px solid var(--button-view-action-fill-disabled);

        .app-text {
            color: var(--button-view-action-fill-disabled);
        }

        &.actual {
            background-color: var(--color-link);
            border-color: var(--color-link);

            .app-text {
                color: var(--color-essential);
            }
        }

    }
    .ant-steps-item-content {
        display: flex !important;
        flex-direction: column;
        justify-content: center;
        min-height: max-content !important;
        padding-left: 3px;
    }
    .ant-steps-item-tail {
        inset-inline-start: 20px !important;
    }
    .ant-steps-item-description{
        display: flex;
        flex-direction: column;
        justify-content: flex-start;

        .materialsTab-fields>:first-child{
            margin-top: 0!important;
        }
    }

    &-fields {
        .katex-display > .katex {
            line-height: 3;
        }

        p {
            font-size: 15px;
            margin: 0;
            word-break: break-word;
        }

        & ol,
        ul {
            font-size: 15px;
            word-break: break-word;
        }

        .action-block-component-editable-area {
            p {
                margin: 0;
            }
        }

        .student-view-header {
            word-break: break-word;
        }

        .test-task-answer {
            &-type {
                margin: 4px 0 20px;
            }

            div:first-child,
            &_type_insert-words {
                font: 600 15px/20px 'Source Sans Pro';
                color: var(--color-main-black);
                margin: 0;
            }
        }

        .expanded-student-view-trainer-material-button-wrapper {
            margin-top: 20px;
        }

        .expanded-exercise-material-full {
            &_student-view {
                min-height: unset;
            }

            &-button-block {
                margin-top: 20px;
            }

            .multiple-answer-options-option > label.checkbox {
                margin-right: 8px;
            }
        }

        .action-block-component {
            font-family: 'Source Sans Pro', sans-serif !important;
            font-size: 15px;
            line-height: 20px;
            padding: 12px;

            &-icon {
                margin-top: 0;
            }
        }

        .exercise-block {
            display: grid;
            grid-template-columns: 1fr;
            grid-gap: 20px;
        }
    }

    //Располагаем шаги по центру для мобилки
    @media (max-width: 475px) {
        .ant-steps.ant-steps-vertical{
            gap: 20px;
        }
        .ant-steps .ant-steps-item-container{
            display: grid;
         	grid-template-columns: 1fr;
            gap: 20px;

            &:has(.task-text-info){
                display: flex;
                flex-direction: column-reverse;
            }
        }
        .ant-steps.ant-steps-vertical >.ant-steps-item .ant-steps-item-icon{
            margin: 0 auto;
            display: block;
        }
        .ant-steps.ant-steps-vertical >.ant-steps-item:not(:last-child)>.ant-steps-item-container>.ant-steps-item-tail {
             display: none;
         }


    }
}
