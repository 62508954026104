.loaders-content-loader{
    display: flex;
    align-items: center;
    justify-content: center;

    &-animation {
        display: flex;
        animation: 2s linear 0s normal none infinite running rot;
    }
    @keyframes rot {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }

    &-logo{
        position: absolute;
    }
}
