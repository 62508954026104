.action-block-component {
    display: flex;
    gap: 12px;
    align-items: center;
    border-width: 1px;
    border-style: solid;
    border-radius: 14px;
    margin: 16px 0;
    padding: 10px 48px 10px 16px;
    position: relative;
    word-break: break-word;

    &,
    * {
        box-sizing: border-box;
    }

    &-icon {
        flex: 0 0 auto;
        width: 32px;
        height: 32px;
        align-self: flex-start;
    }

    &-editable-area {
        flex: 1;
        min-height: 20px;

        p {
            min-height: inherit;
        }
    }

    &_type {
        &_INFO_BLOCK {
            background: var(--color-stroke-maingray);
            border-color: var(--color-link);

            svg.action-block-component-icon path {
                stroke: var(--color-link);

                &:last-child {
                    fill: var(--color-link);
                }
            }
        }

        &_WARNING_BLOCK {
            background: var(--styled-number-color-yellow-background);
            border-color: var(--styled-number-color-yellow-text);

            svg.action-block-component-icon path {
                stroke: var(--styled-number-color-yellow-text);

                &:last-child {
                    fill: var(--styled-number-color-yellow-text);
                }
            }
        }

        &_THEOREM_BLOCK {
            background: var(--styled-number-color-green-background);
            border-color: var(--color-green-main);
        }

        &_QUOTE_BLOCK {
            background: var(--styled-number-color-red-background);
            border-color: var(--styled-number-color-red-text);

            svg.action-block-component-icon path {
                stroke: var(--styled-number-color-red-text);

                &:last-child {
                    fill: var(--styled-number-color-red-text);
                }
            }
        }

        &_DEFINITION_BLOCK {
            background: var(--styled-number-color-purple-background);
            border-color: var(--styled-number-color-purple-text);

            svg.action-block-component-icon path {
                stroke: var(--styled-number-color-purple-text);

                &:last-child {
                    fill: var(--styled-number-color-purple-text);
                }
            }
        }
    }
}
