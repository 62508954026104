.assignedHomeworkLine {
    padding: 0 15px;

    &-icon {
        width: 44px;
        height: 44px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;


        &:hover {
            background-color: var(--color-essential);
            box-shadow: 0 8px 32px rgba(20, 49, 127, 0.08);
            border-radius: 6px;
        }
    }

    &-icon-wrapper {
        display: grid;
        grid-template-columns: repeat(auto-fill, 44px);
        align-items: center;
        grid-auto-flow: column;
    }

    &-assignment-date {
        font-size: 13px;
        line-height: 20px;
        color: var(--color-main-black);
    }
    &-expand-icon{
        display: none;
        cursor: pointer;

        &_isVisible {
            display: flex;
        }
        &_isOpened {
            transform: rotate(180deg);
        }
    }
}
