.themeCodifier {
    padding: 8px 24px 8px 20px !important;
    width: fit-content;
    margin-top: 8px;

    &_isSelected {
        border-radius: 6px;
        background-color: var(--color-stroke-maingray);
    }

    &_selectChild {
        border-radius: 6px;
        background-color: var(--color-stroke-maingray);
    }

    &_isChild {
        .dropdownActionsForm {
            margin-left: 20px !important;
        }
    }

    &-title {
        display: flex;
        align-items: center;
        min-height: 24px;
        min-width: 180px;
        max-width: 608px;
        column-gap: 8px;
    }

    &-codifierValue {
        color: var(--color-grey-descriptor);
    }

    &-text {
        font-size: 15px;
        line-height: 20px;
        color: var(--color-main-black);
        font-weight: 400;
        cursor: pointer;

        &_isSelected {
            font-weight: 600;
            color: var(--color-link);
        }

        &_isChild {
            cursor: default;

            .theme_tooltip {
                left: -4px;
            }
        }

        &_hasCheckbox {
            cursor: pointer;
        }

        &_inactive {
            cursor: default;
            color: var(--color-gray-inactive);
        }
    }

    &-icons {
        display: flex;
        margin-left: 8px;
        align-items: center;
        position: relative;
        .theme_tooltip {
            z-index: 1;
            top: 36px;
            left: -28px;
        }
    }

    &-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 4px;
        cursor: pointer;
        height: 24px;
        width: 24px;
        border-radius: 4px;

        &:hover {
            background-color: var(--color-stroke-maingray);
        }

        &_isSelected {
            &:hover {
                background-color: var(--color-dashboard);
            }
        }

        svg {
            path {
                fill: var(--color-link);
            }
        }

        &_clickMore {
            background-color: var(--color-dashboard);
        }
    }

    &-questions {
        font-size: 13px;
        font-weight: 600;
        line-height: 16px;
        color: var(--color-grey-middle-grids);
        margin-right: 6px;

        &_inactive {
            color: var(--color-gray-inactive);
        }
    }

    &-questions-container {
        display: flex;
    }
}

.codifier-skill {
    .dropdownActionsForm {
        margin-left: 0px !important;
    }
}
