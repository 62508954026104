.task_view {
    display: flex;
    flex-direction: row;
    align-items: center;

    .checkbox{
        svg{
            margin: 0;
        }
    }

    svg {
        margin: 0 4px 0 12px;
        flex: 1 0 22px;

        text {
            size:13px;
        }
    }

    &-title {
        margin-left: 8px;
       -webkit-line-clamp: 2; /* Число отображаемых строк */
        display: -webkit-box; /* Включаем флексбоксы */
       -webkit-box-orient: vertical; /* Вертикальная ориентация */
        overflow: hidden; /* Обрезаем всё за пределами блока */
        color: var(--color-main-black);
        font-size: 13px;
        max-width: 200px;
        cursor: pointer;

        &_normal {
            max-width: 200px;
        }

        &_template {
            max-width: 180px;
        }

        &_disabledSelect {
            min-width: unset;
            max-width: unset;
        }
    }
}
