.edit-lesson-modal {
    display: flex;
    flex-direction: column;
    gap: 20px;

    &__wrapper{
        position: absolute !important;

        .ant-modal-content {
            padding: 20px;
        }
    }
    &__title-input {
        display: flex;
        flex-direction: column;
        gap: 4px;
    }
    &__type-checkbox {
        display: flex;
        flex-direction: column;
        gap: 12px;

        &_switcher {
            display: flex;
            gap: 8px;
        }
        &_timepicker {
            display: flex;
            flex-direction: column;
            gap: 12px;
            max-height: 0;
            opacity: 0;
            transition: all ease .4s;

            &.visible {
                max-height: 300px;
                opacity: 1;
            }
        }
    }
    &__btns {
        display: flex;
        gap: 12px;
    }
}
