.option-popup-wrapper-container {
    * {
        box-sizing: border-box;
    }

    padding-top: 19px;
    width: fit-content;
    height: fit-content;
    z-index: 10;
}

.option-popup-wrapper {
    display: flex;
    flex-direction: column;
    width: 360px;
    background: white;
    box-shadow: 0px 8px 32px rgba(20, 49, 127, 0.08);
    border: 1px solid #eef4fb;
    border-radius: 14px;
    position: relative;

    &-btns {
        display: flex;
        justify-content: space-between;
        padding: 8px 16px 16px 16px;
    }

    &-save {
        width: 200px;
        border-radius: 6px;
    }

    &-cancel {
        width: 116px;
        border: 1px solid var(--color-gray-inactive);
        border-radius: 6px;
        padding: 0;

        .button-text {
            color: var(--color-gray-inactive);
        }
    }

    &::before {
        content: '';
        background: inherit;
        border: inherit;
        border-bottom: none;
        border-right: none;
        width: 26px;
        height: 26px;
        transform: rotate(45deg);
        position: absolute;
        top: -14px;
        left: 24px;
    }
}
