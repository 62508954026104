.theme_tooltip {
    position: absolute;
    top: 14px;
    border: 1px solid #EEF4FB;
    border-radius: 14px;
    width: 200px;
    &-tooltip_container{
        position: relative;
        background-color: #FFFFFF;
        font-size: 15px;
        line-height: 20px;
        border-radius: 14px;
        border: none;
        padding: 12px 0 12px;
        &:before{
            content: '';
            position: absolute;
            left: 28px;
            top: -11px;
            width: 20px;
            height: 20px;
            transition: 0.15s;
            transform: rotate(45deg);
            background-color: #FFFFFF;
            border: 1px solid #EEF4FB;
            border-right: none;
            border-bottom: none;
        }
    }
    &-tooltip_content{
        display: flex;
        flex-direction: column;
        align-items: baseline;
    }
    &-tooltip_button{
        display: flex;
        flex-direction: row;
        align-items: center;
        position: relative;
        svg{
            path{
                stroke:#0088E9;
            }
            margin-right: 14px;
            margin-left: 26px;
        }
        #plus_icon{
            path{
                fill:#0088E9;
                stroke:none;
            }
        }

        #replace_icon{
            width: 18px;
        }

        cursor: pointer;
        #pencil_circle{
            margin-right: 7px;
        }
        width: 100%;
        height: 44px;
        &:hover{
            background-color: #EEF4FB;
        }
    }
}
