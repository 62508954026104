.single-question-container {
    display: flex;
    padding: 12px 20px;
    border-radius: 14px;
    background: var(--color-essential);
    cursor: pointer;
    max-width: 450px;

    &.dragging {
        opacity: 0;
        overflow: hidden;
        border: 1px solid var(--styled-number-color-green-text);
        &:hover {
            cursor: grabbing;
            cursor: -moz-grabbing;
            cursor: -webkit-grabbing;
        }
    }
    &.active-border {
        border: 1px solid var(--styled-number-color-green-text);
    }
    &.excluded {
        border: 1px solid var(--color-stroke-maingray) !important;
        color: var(--button-view-action-fill-disabled);
        box-shadow: 0 6px 18px 0 #0000000f;

        &:hover {
            box-shadow: none;
            cursor: pointer !important;
        }
    }

    .AppTextStyle>span{
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
    }
    &__text {
        font-size: 14px;
        font-family: "Source Sans Pro", sans-serif;
    }
}
.questions-block-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px 24px;
    border-radius: 20px;
    border: 1px solid var(--color-stroke-maingray);
    background: var(--color-gray-second);
    cursor: pointer;
    max-width: 450px;

    &.right {
        border: 1px solid var(--styled-number-color-green-background);
        background: var(--styled-number-color-green-background);
    }
    &.active-block {
        border: 1px solid var(--button-view-action-fill);
        background: var(--color-stroke-maingray);
    }
    &.dragging {
        border-color: #6865FF;
        box-shadow: 0 8px 32px 0 #14317F14;
        opacity: 0;
        overflow: hidden;

        &.right {
            border: 1px solid var(--button-view-action-fill);
            background: var(--color-stroke-maingray);
        }

        &:hover {
            cursor: grabbing;
            cursor: -moz-grabbing;
            cursor: -webkit-grabbing;
        }
    }
    &__header{
        display: grid;
        grid-template-columns: 1fr auto;
        align-items: center;
        &_left {
            display: flex;
            align-items: center;
            gap: 4px;
        }
    }
    &__list {
        display: flex;
        flex-direction: column;
        gap: 12px;
    }
}
