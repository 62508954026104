.studentTaskTab {
    display: grid;
    gap: 10px;
    position: relative;
    height: 100%;
    padding-top: 24px;


    @media (max-width: 768px){
        display: block;
    }


    .task-confirm-block {
        display: flex;
        flex-direction: column;
        gap: 20px;

        &__info {
            display: flex;
            flex-direction: column;
            gap: 10px;
        }
        &__buttons {
            display: flex;
            align-items: center;
            max-width: 200px;
        }
    }
    .timer {
        top: 10px;
        position: sticky;
        width: 120px;
        height: 120px;
        display: flex;
        align-items: baseline;
        justify-content: center;
        grid-area: 1/2/3/3;

        @media (max-width: 768px) {
            position: relative;
            grid-area: unset;
            margin-top: 30px;
            top: unset;
        }

    }
    .student-task-btn-block {
        flex-wrap: wrap-reverse;
        gap: 10px;
    }

    &-tabs {
        display: flex;
        overflow-x: auto;
    }

    &-tab {
        flex: 0 0 200px;
        margin-right: 32px;
        margin-bottom: 7px;

        &:last-child {
            margin-right: 0;
        }

        @media screen and (max-width: 1024px) {
            margin-right: 24px;
        }
    }


    &-empty{
        background-image: url('../../../../../../assets/images/empty-subject.png');
        background-repeat: no-repeat;
        background-position: center top;
        max-height: 700px;
        min-height: 400px;
        height: 100%;
        width: 100%;
        background-size: contain;

        &-title {
            color: var(--color-text-color);
            font-size: 24px;
            line-height: 28px;
            font-weight: 600;
        }
    }

    #scroll-bar::-webkit-scrollbar {
        height: 4px;
        background-color: var(----color-stroke-maingray);
    }

    #scroll-bar::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background-color: var(--color-scroll-bar-background);
    }
    #scroll-bar::-webkit-scrollbar-track {
        background-color: var(----color-stroke-maingray);
    }
}
