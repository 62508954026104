.expanded-material {
    &-exercise {

        &-question {
            .__Latex__ > p {
                font-size: 18px;
                line-height: 20px;
                color: var(--color-main-black);
                margin: 0;
            }
        }

        &-options {
            .__Latex__ > p {
                font: 18px/1.2 'Source Sans Pro';
                color: var(--color-main-black);
                margin: 0;
            }
        }
    }

    &-table-cell {
        border: none;
        font-family: 'Source Sans Pro' sans-serif !important;
        font-size: 15px;
        line-height: 20px;

        .__Latex__ {
            font-size: 15px !important;
        }
    }

    &-preview {

        &-header:first-child {
            margin-top: 0;
        }
    }

}
