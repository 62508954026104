.drop-area {
    display: flex;
    flex-direction: column;
    gap: 12px;
    height: 100vh;
    max-height: 500px;
    overflow-y: auto;
    scrollbar-gutter: stable;
    padding-right: 10px;

    &::-webkit-scrollbar {
        width: 3px;
        height: 3px;
        background-color: var(--color-stroke-maingray);
        border-radius: 2px;
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 2px;
        background-color: var(--schedule-item-indicator-before-fill);
    }

    &::-webkit-scrollbar-track {
        border-radius: 2px;
        background-color: var(--color-stroke-maingray);
    }

    &__empty-list{
        max-width: 100%;
    }
}
