.kinescope-video {

    &__wrapper {
        position: relative;
        padding-top: 56.25%;
        width: 100%;

        iframe {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
        }
    }
}
