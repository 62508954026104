.detailed-question {
    &-answer-option {
        &:focus,
        &:focus-within,
        &:active,
        &:hover {
            background: none !important;
        }
    }

    &-answer-option p {
        font-size: 14px;
        font-weight: 400;
        width: 180px;
    }

    &-option-name {
        font-weight: 600;
        font-size: 16px;
        margin-left: 8px;
    }

    &-option-name-smart {
        font-weight: 600;
        font-size: 16px;
        margin-left: 8px;
        width: 300px;
    }
}

.center-flex {
    display: flex;
    width: 80%;
    justify-content: space-between;
    margin-left: 20px;
}

.center-vertical-smart {
    width: 240px;
    display: flex;
    align-items: center;
}

.center-vertical-text {
    width: 160px;
    display: flex;
    align-items: center;
    margin-left: 10px;
}

.center-vertical-file {
    width: 100px;
    display: flex;
    align-items: center;
}
