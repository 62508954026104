.addFile {

    &-header {
        font-weight: 600;
        font-size: 23px;
        padding-top: 5px;
        margin: 0;
    }

    &-title {
        font-weight: 600;
        font-size: 23px;
        padding-top: 5px;
    }

    &-uploadFiles {
        width: 0.1px;
        height: 0.1px;
        opacity: 0;
        overflow: hidden;
        position: absolute;
        z-index: -1;
        pointer-events:none;
    }

    &-labelFileUploader {
        border: 1px dashed #0088E9;
        cursor: pointer;
        width: 40px;
        height: 40px;
        border-radius: 4px;
        margin-left: 14px;
        display: flex;
        align-items: center;
        justify-content: center;

        &:hover {
          border: 1px solid #0088E9;
          background-color: var(--color-link);
          svg {
            fill: var(--color-essential);
          }
        }
    }
    &-file-wrapper{
        &_hasAttr {
            position: relative;

            &:hover::after {
                content: attr(data-title);
                position: absolute;
                right: 0;
                top: 0;
                transform: translateY(-100%);
                width: 100%;
                z-index: 1;
                background: rgba(243, 243, 243, 0.7);
                font-size: 11px;
                padding: 5px 10px;
                border: 1px solid #bab6b6;
            }
        }
    }
}
