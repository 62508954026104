body {
    margin: 0;
    font-family: 'Source Sans Pro', sans-serif;
    color: var(--color-text-color);
    overflow: hidden;
}

* {
    font-family: 'Source Sans Pro', sans-serif;
    scrollbar-color: var(--schedule-item-indicator-before-fill) var(--color-stroke-maingray);
    scrollbar-width: auto;
}

p {
    margin: 0;
}

.layout,
.layout_white {
    padding: 20px 80px;
    width: 100%;
    height: 100vh;
    overflow-y: auto;

    &>.header-mobile {
            position: fixed;
            display: flex;
            width: 100%;
            height: 85px;
            padding: inherit;
            top: 0;
            left: 0;
            right: 0;
            border-bottom: 1px solid #D3E0EF;
            background-color: #FFFFFF;
            z-index: 500;
    }
    &:has(>.header-mobile) {
        @media (max-width: 768px) {
            margin-top: 80px !important;
        }
    }


}

.layout_white {
    background-color: var(--color-essential);
}

.layout_question_bank {
    width: 100%;
    height: 100vh;
    overflow-x: hidden;
}

@media screen and (max-width: 1024px) {
    .layout,
    .layout_white {
        padding: 22px 34px;
    }
}

.row {
    display: flex;
}

#root {
    background-color: var(--color-dashboard);
}

@media screen and (min-width: 2000px) {
    html {
        font-size: 12px;
    }
}

.storybook-logo {
    position: absolute;
    top: 60px;
    right: 60px;
    background: url('./storybook.png');
    width: 40px;
    height: 40px;
    background-position: center;
    background-size: contain;
}

// TODO: remove after update FieldDropdown
.select-option_focus span {
    margin-left: 8px;
}
