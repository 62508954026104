.questions-list-bar {
    .question-number {
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        border: 1px solid;
        cursor: pointer;

        &.disabled {
            pointer-events: none;
        }
        &.active {
            border-color: var(--color-link);
            background-color: var(--color-link);
        }
        &.common {
            border-color: var(--color-gray-inactive)
        }
        &.add {
            background-color: unset;
            border-style: dashed;
        }
    }
    .question-item {
        display: grid;
        grid-template-columns: 230px auto;
        gap: 30px;
        align-items: center;
        margin-bottom: 40px;

        &__description {
            display: flex;
            flex-direction: column;
            gap: 4px;
        }
        &__delete-btn {
            width: 40px;
            height: 40px;
            display: flex;
            justify-content: center;
            align-items: center;
            border: 1px solid var(--color-red-main);
            border-radius: 4px;
            cursor: pointer;

            &:hover {
                background-color: var(--color-red-main);

                path {
                    stroke: var(--color-essential);
                }
            }
        }
    }
    .ant-steps .ant-steps-item-title::after{
        inset-inline-start: 0;
    }
}

