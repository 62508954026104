.attendanceMarkAdd {
    &-mark {
        display: flex;
        align-items: center;
        width: 100%;
        column-gap: 14px;

        & span {
            font-size: 15px;
            line-height: 20px;
            color: #000000;
        }
    }

    &-textArea {
        width: 100%;
        height: 80px;
        max-height: 80px;

        &::placeholder {
            font: 15px/20px 'Source Sans Pro';
            text-align: left;
            vertical-align: top;
            color: #919399;
        }

        &_needComment {
          border-color: #EA5959;
        }
    }

    &-headerAction {
        width: 32px;
        height: 32px;
        cursor: pointer;
        margin-left: auto;

        &_isChangeForm {
          display: flex;
          align-items: center;
          justify-content: center;
      }
    }

    &-addedMark {
        display: flex;
        flex-direction: column;
        align-items: center;

        margin-right: 14px;

        & .mark {
            margin-right: 0 !important;
        }
    }

    &-trash {
        margin-top: 22px;
        cursor: pointer;
    }

    &-header {
        display: flex;
        justify-content: space-between;
        align-items: center;

        margin: 17px 0;

        &_added {
            display: flex;
        }
    }

    &-currMark {

        &:not(.attendanceMarkAdd-currMark_selected) .mark {
            cursor: pointer;
            background: #EDEEF2;
        }

        &:not(.attendanceMarkAdd-currMark_selected) .mark .mark-group {
            cursor: pointer;
            color: #919399!important;
        }

        &_withoutComment {
            margin-right: 10px;
        }
    }

    &-footer {
        display: flex;
        justify-content: space-between;

        margin-top: 18px;
    }

    &-button {
        position: relative;
        width: 49%;

        &_isCancel > button {
            border-color: #C0C2C9;

            & .button-text {
                color: #C0C2C9;
            }
        }

        &:hover .tooltip {
            display: block;
        }

        & .tooltip {
            position: absolute;
            display: none;
            top: -40px;
            left: 15%;
        }
    }

    &-button-text {
        width: 100%;
        text-align: center;
    }

    &-commentInputContainer{
        display: flex;
        position: relative;
        flex-direction: column;
        width: 100%;
        padding-bottom: 12px;
        span{
            position: absolute;
            bottom: -8px;
            left: 6px;
        }
    }

}
