.course-card-skeleton {
    display: flex;
    flex-direction: column;
    background-color: var(--color-stroke-maingray);
    border-radius: 8px;
    width: 100%;

    &.inverse {
        background-color: var(--color-essential);
    }
    &.course-card {
        padding: 12px;
        gap: 8px;

    }
    &.title {
        width: unset;
        background-color: unset;
    }
    &.button {
        justify-content: center;
        align-items: center;
        padding: 16px;
    }
    &.option {
        align-items: center;
        padding: 12px 8px;
        gap: 4px;

        :first-child .app-skeleton-button {
            min-width: unset !important;
            border-radius: 6px;
        }
    }
    &.lesson-card {
        padding: 12px;
        gap: 12px;

        .lesson-card{
            &__header{
                display: flex;
                flex-direction: column;
                gap: 8px;
            }
            &__list {
                display: flex;
                flex-direction: column;
                gap: 4px;

                &_item {
                    display: flex;
                    align-items: center;
                    gap: 4px;

                    :first-child .app-skeleton-button {
                        min-width: unset !important;
                        border-radius: 6px;
                    }
                }
            }
        }

    }

    span.app-skeleton-button:after {
        background: linear-gradient(90deg, #D3E0EF 20%, #e4eefa 37%, #D3E0EF 80%) !important;
    }
}
