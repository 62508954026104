.empty-content {
    position: relative;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto 1fr;
    overflow: hidden;
    box-sizing: border-box;
    align-items: center;
    justify-content: center;
    gap: 20px;
    border: 1px solid var(--button-border-default);
    border-radius: 14px;
    background-color: var(--background-color);
    padding: 16px;
    height: calc(100vh - 150px);
    text-align: center;

    &__image {
        justify-self: center;
        max-width: 650px;
        height: 100%;
        overflow: hidden;

        img {
            width: auto;
            height: 100%;
        }
    }

}
