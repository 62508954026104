.collapse-with-button {
    border-radius: 20px;
    background-color: var(--color-essential);

    &__content {
        &_collapse-btn {
            display: flex;
            align-items: center;
            justify-content: center;
            border-top: 1px solid var(--button-view-action-fill);
            padding-top: 12px;
        }
    }
    &__header {
        //Добавляем отступы, так как антд-шные не подходят
        padding: 8px 16px;
    }
    .ant-collapse{
        background-color: transparent;
        border-radius: 20px;

        &-content-box {
            padding-top: 0 !important;
        }
        &>.ant-collapse-item:last-child,
        &>.ant-collapse-item:last-child>.ant-collapse-header,
        .ant-collapse-item:last-child >.ant-collapse-content {
            border-radius: 0 0 20px 20px !important;
        }
    }
}
