.app-button-dropdown{

    &__scrollable-container {
        background: var(--color-essential);
        border-radius: 8px;
        box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
        overflow: hidden;

        padding-right: unset !important;
        scrollbar-gutter: unset !important;

        max-height: 192px;
        overflow-y: auto;
        scrollbar-width: thin;
        scrollbar-color: var(--schedule-item-indicator-before-border-color);

        /* Работает в Chrome, Edge и Safari */
        &::-webkit-scrollbar {
            width: 4px;
            background-color: var(--color-stroke-maingray);
        }

        &::-webkit-scrollbar-thumb {
            background-color: var(--schedule-item-indicator-before-border-color);
            border-radius: 40px;
        }

        .app-button-dropdown-menu {
            box-shadow: none;
            padding: 4px 0 !important;

            .app-button-dropdown-menu-item{
                border-radius: 0 !important;
                &:hover {
                    background-color: #EEF4FB;
                }
            }
        }

    }
    &__container {
        background: #fff;
        border-radius: 8px;
        box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
        overflow: hidden;

        &_list{
            max-height: 192px;
            overflow-y: auto;
            scrollbar-width: thin;
            scrollbar-color: var(--schedule-item-indicator-before-border-color);

            /* Работает в Chrome, Edge и Safari */
            &::-webkit-scrollbar {
                width: 4px;
                background-color: #eef4fb;
            }

            &::-webkit-scrollbar-thumb {
                background-color: var(--schedule-item-indicator-before-border-color);
                border-radius: 40px;
            }

            .app-button-dropdown-menu {
                box-shadow: none;
                padding: 4px 0 !important;

                .app-button-dropdown-menu-item{
                    border-radius: 0 !important;
                    &:hover {
                        background-color: #EEF4FB;
                    }
                }
            }
        }
    }
    &-open {
        svg {
            transform: rotate(180deg);
            transition: all ease .2s;
        }
    }

    &__btn {
        display: flex;
        justify-content: center;
        align-items: center;

        &-default {
            background-color: transparent;
            border-color: var(--button-border-default) !important;

            &.app-button-dropdown-open {
                background-color: var(--button-view-action-fill);
                border-color: var(--button-view-action-fill);

                .app-text {
                    color: var(--button-color-text);
                }
                svg path {
                    fill: var(--button-color-text);
                }

            }
        }
        &-primary {
            .app-text {
                color: var(--button-color-text);
            }
            svg path {
                fill: var(--button-color-text);
            }
        }
        &-lg {
            height: 50px !important;
            padding: 15px 34px !important;
        }
        &_space {
            &-item {
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
    }
}
