.attendanceTab {
    width: 100%;


    @media (max-width: 475px) {
        overflow-x: scroll;
        &::-webkit-scrollbar {
            height: 2px;
            width: 2px;
            scroll-padding-left: 8px;
            background-color: var(--color-stroke-maingray);
        }

        &::-webkit-scrollbar-thumb {
            width: 2px;
            border-radius: 2px;
            background-color: var(--schedule-item-indicator-before-border-color);
        }

        &::-webkit-scrollbar-track {
            background-color: var(--color-stroke-maingray);
        }
    }
    &-table {
        width: 100%;
        table-layout: fixed;
        border-collapse: collapse;

        @media (max-width: 475px) {
            table-layout: unset;
        }
    }
    &-tableHead {
        font-size: 13px;
        line-height: 16.34px;
        color: #68788D;
        text-align: left;
    }
    &-studentsHeader {
        padding: 20px 0 20px 0;
    }
    &-marksHeader {
        padding-right: 40px;
        @media screen and (max-width: 1024px) {
            padding-right: 20px;
        }
    }


        @media (max-width: 475px) {
            td {
                padding: 0 5px !important;
            }
            th {
                white-space: nowrap;
                padding: 20px 5px !important;
            }
        }

}
