.criteria-table-content {
    background-color: var(--color-essential);
    border-radius: 20px;
    border: 1px solid var(--schedule-border-color);
    overflow: hidden;
    z-index: 1;

    .ant-table-wrapper {
        .ant-table-thead > tr > th,
        .ant-table-tbody > tr.ant-table-row:hover > td {
            background-color: var(--color-stroke-maingray);
        }
        .ant-table-tbody > tr > td,
        .ant-table-summary > tr > td {
            border-bottom: 1px solid var(--button-border-default);
        }
        .ant-table-tbody > tr:last-child > td {
            border-bottom: none !important;
        }
        .ant-table-tbody > tr > td:first-child > div,
        .ant-table-summary > tr > td:first-child > div {
            padding-left: 20px;
        }
        .ant-table-thead > tr > th {
            border-top: 16px solid var(--color-essential);
            border-bottom: 16px solid var(--color-essential);

            &:before {
                width: 0 !important;
            }
        }
        .ant-table-container table > thead > tr:first-child > *:first-child {
            border-start-start-radius: 24px;
            border-end-start-radius: 24px;
            border-left: 16px solid var(--color-essential);
        }
        .ant-table-container table > thead > tr:first-child > *:last-child {
            border-end-end-radius: 24px;
            border-start-end-radius: 24px;
            border-right: 16px solid var(--color-essential);
        }
        .ant-table-summary > tr > td:not(:first-child) > div {
            justify-content: center;
        }
        table {
            border-collapse: separate;
            border-spacing: 0;
        }
        .ant-table-summary tr > td:last-child > div {
            padding-right: 16px;
        }
        .ant-table-body tr > td:last-child {
            padding-right: 32px;
        }

    }

    .clean-filters-btn {
        display: flex;
        cursor: pointer;
        width: 20px;
    }

    .criteria-num {
        margin: 0 auto;
        cursor: pointer;
        max-width: max-content;
        max-height: 20px;

        &.bordered {
            display: flex;
            align-items: center;
            justify-content: center;
            border: 2px solid;
            border-radius: 10px;
            max-width: 23px !important;
            width: 23px;
            &:not(:hover) {
                background: none !important;

            }
        }
    }
}
