.subjectName {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    &-container{
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 4px 0;
        width: fit-content;
        &_highlighted {
            padding-left: 12px;
            width: 204px;
            border-radius: 6px;
            background-color: var(--color-stroke-maingray);
        }
    }

    &-title {
        display: flex;
        align-items: center;
        cursor: pointer;
        &_highlighted {
            color: var(--color-link);
            font-weight: 600;
        }
        &_disabled {
            cursor: default !important;
            color: var(--color-gray-inactive);
        }
    }

    &-open {
        transform: rotate(270deg);
        svg {
            path {
                fill: var(--color-gray-inactive);
            }
        }
        &_isClickShow {
            transform: rotate(0deg);
            svg {
                path {
                    fill: var(--color-link) !important;
                }
            }
        }
        &_disabled {
            path {
                fill: var(--color-gray-inactive);
            }
        }
    }

    &-subject {
        margin-left: 8px;
        font-size: 17px;
        line-height: 24px;
        font-weight: 600;
    }

    &-buttons {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        .theme_tooltip {
            z-index: 1;
            top: 36px;
            left: 12px;
        }
    }

    &-icon {
        width: 24px;
        height: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 8px;
        border-radius: 4px;
        cursor: pointer;

        svg {
            path {
                fill: #0088E9;
            }
        }

        &:hover {
            background-color: #EEF4FB;
        }

        &_isClickMore {
            background-color: #EEF4FB;
        }
    }
    &-children_container{
        .codifier_dropdown_grade {
            &-container{
                margin-left: 20px;
                &_highlighted{
                    margin-left: 0;
                }
            }
        }
        &_opened{
            margin-bottom: 32px;
        }
        .dropdownActionsForm {
            margin-left: 20px;
        }
    }
    .dropdownActionsForm{
        margin-bottom: 8px;
        width: 400px;
        .select-suggest_focus {
            padding: 4px 0;
            background-color: #fff;
            z-index: 1;
        }
    }
}
