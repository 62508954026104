.app-calendar{
    width: 312px;
    box-shadow: 0 8px 32px rgba(20, 49, 127, 0.08);
    border-radius: 12px;
    overflow: hidden;
    padding-top: 12px;
    background-color: var(--color-essential);
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */

    &-element{
        padding: 0 20px 7px 20px;
    }

    &-school-day{
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    &-line{
        padding-bottom: 12px;
        display: flex;
        align-items: center;
        width: 100%;
        gap: 8px;
    }

    &-text-container{
        display: flex;
        flex-direction: row;
        gap: 12px;
    }

    &-picker{
        &-content th{
            color: var(--color-grey-middle-grids) !important
        }

        &-body{
            padding: 16px 0 0 0 !important;
        }

        &-calendar-date{
            height: 32px !important;
            min-width: 32px !important;
        }

        &-cell-inner{
            line-height: 32px !important;
        }

        &-cell-disabled:before{
            height: 32px !important;
        }
    }

    &-divider{
        width: 100%;
        margin-bottom: 16px;
        border-bottom: 1px solid var(--color-stroke-maingray);
    }
    &-text {
        font-style: normal;
        font-weight: 600;
        font-size: 15px;
        line-height: 20px;
    }

}
