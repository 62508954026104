.app-switch{
    min-width: 32px !important;
    height: 20px !important;

    &-handle{
        width: 16px !important;
        height: 16px !important;
        inset-inline-start: calc(100% - 18px) ;
    }

    &-checked{
        .app-switch-handle{
            inset-inline-start: calc(100% - 18px) !important;
        }
        .app-switch-inner-checked svg{
            position: absolute;
            opacity: 1;
            visibility: visible;

        }
        .app-switch-inner-unchecked svg{
            position: unset;
            opacity: 0;
            visibility: hidden;

        }
    }
    &-inner-checked{
        svg {
            position: unset;
            opacity: 0;
            visibility: hidden;
            right: 6px;
            top: 7px;
            transition: opacity ease-out .5s;
        }
    }
    &-inner-unchecked {
        svg{
            position: absolute;
            opacity: 1;
            visibility: visible;
            top: 6px;
            left: 6px;
            transition: opacity ease-in .5s;

        }
    }

}
