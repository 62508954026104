.tasks-questions-list{
    display: flex;
    flex-direction: column;
    gap: 18px;

    &__title {
        display: flex;
        align-items: baseline;
        gap: 40px;
    }

}
