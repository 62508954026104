.app-skelet {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #FFFFFF;
    opacity: 0.6;

    &::after {
        content: '';
        display: block;
        position: absolute;
        transform: translateX(-100%);
        top: 0;
        height: 100%;
        width: 170%;
        background: linear-gradient(to right, transparent 0%, #1777ff 50%, transparent 100%);
        opacity: 0.2;
        animation: load-1 1s linear infinite;
    }
}
@keyframes load-1 {
    0% {
        transform: translateX(-100%);
    }
    100% {
        transform: translateX(100%);
    }
}
