.lessons-col-tabs {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 4px;

    @media (max-width: 768px) {
        grid-template-columns: repeat(2, 1fr);
    }

    &__item {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        cursor: pointer;
        border: 1px solid var(--button-border-default);
        border-radius: 6px;
        height: 50px;
        padding: 10px 8px;
        width: 100%;
        overflow: hidden;

        &.disabled {
            pointer-events: none;
            cursor: default;
        }
        &.selected {
            background-color: var(--color-link);
            border-color: var(--color-link);

            .app-text {
                color: var(--color-essential);
            }
        }
        &_count {
            .app-text {
                font-weight: 600;
            }
        }
        &_title {
            display: flex;


            max-width: 100%;

            .app-text {
                color: var(--color-main-dark-icons);
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
            }
        }
    }
}
