.studentTaskRateButton {
    &-formContainer {
        position: relative;
        display: flex;
        margin: 0 0 20px 0;
    }

    &-addMarkFormWrapper {
        width: 440px;
        position: absolute;
        top: calc(100% + 12px);
        padding: 15px 26px 32px;
        box-sizing: border-box;
        border: 1px solid var(--color-stroke-maingray);
        background: var(--color-dashboard);
        box-shadow: 0 8px 32px rgba(20, 49, 127, 0.08);
        border-radius: 14px;
        z-index: 30;

        & .input {
            max-width: 393px;
        }
    }

    &-formBackground {
        position: fixed;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        background: #e9e9e9;
        opacity: .5;
        z-index: 2;
    }
}
