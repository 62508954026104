.adding-questions-content {
    display: flex;
    flex-direction: column;
    gap: 32px;

    &__empty {
        padding: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    &__container {
        display: grid;
        grid-template-columns: max-content 1fr;
        gap: 32px;

        @media (max-width: 768px) {
            grid-template-columns: 1fr;
            gap: 20px;
        }

        &_legend{
            &-months {
                display: flex;
                flex-direction: column;
                gap: 12px;

                @media (max-width: 768px) {
                    flex-direction: row;
                    align-items: center;
                    flex-wrap: wrap;
                    grid-area: unset;
                }

            }
        }

        .chart-tooltip {
            display: flex;
            flex-direction: column;
            font-size: 15px;
            width: 100%;
            padding: 12px;

            &__title {
                color: var(--color-grey-descriptor);
            }
            &__row{
                display: flex;
                justify-content: space-between;
                align-items: center;

                &>div {
                    color: inherit;
                }

            }

        }
    }
}
