.methodist-trainer-card{
    display: flex;
    flex-direction: column;
    padding: 24px 32px;
    background-color: var(--color-essential);
    border: 1px solid var(--color-blue-border);
    border-radius: 20px;

    &-line{
        display: flex;
        flex-direction: row;
        gap: 4px;
    }

    &-button{
        margin-top: 16px;
        width: 200px;
    }
}
