.onlineLessonTab {
    height: max-content;
    padding: 20px 0;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;

    @media screen and (max-width: 1400px) {
        justify-content: space-between;
    }

    &-videoContainer {
        display: flex;
        gap: 20px;
        margin-right: 120px;
        margin-bottom: 50px;
        flex-wrap: wrap;

        @media screen and (max-width: 1400px) {
            margin-right: 60px;
        }
    }

    &-videoAvailability {
        display: flex;
        align-items: center;
        justify-content: space-between;
        min-height: 40px;
        margin-bottom: 12px;
    }

    &-videoAvailabilityTitle {
        font-weight: 600;
        font-size: 24px;
        line-height: 28px;
        margin: 0 20px 0 0;
    }

    &-video {
        width: 560px;
        height: 308px;
        background-image: url('../../assets/images/record-bg.png');
        background-size: contain;
        background-repeat: no-repeat;

        @media screen and (max-width: 1024px) {
            width: 490px;
            height: 270px;
        }
    }

    &-rooms{
        display: flex;
        flex-wrap: wrap;
        margin: 24px 0 36px;

        .roomCard{
            margin-right: 12px;
            margin-bottom: 12px;
        }

        .roomCard-card{
            width: 244px;
            background-color: var(--color-essential);
        }

        .roomCard-teachers{
            height: 50px;
        }
    }

    &-roomsContainer{
        display: flex;
        flex-direction: column;
    }

    &_isStudent {
        flex-direction: column;
    }

    &-studentMarksTitle {
        font-weight: 600;
        font-size: 24px;
        line-height: 28px;
        margin: 8px 0 14px 0;
    }

    &-studentMarks {
        max-width: 560px;
    }

    &-mark {
        margin-bottom: 20px;

        &:last-child {
            margin-bottom: 0;
        }
    }

    &-empty {
        background-image: url('../../assets/images/empty-online-lesson.png');
        background-repeat: no-repeat;
        background-position: center;
        width: 100%;
        height: 60vh;
        background-size: contain;
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
        margin-top: 3%;

        &-title {
            color: var(--color-text-color);
            font-size: 24px;
            line-height: 28px;
            font-weight: 600;
        }
    }

    &-record {

        &-wrapper {
            display: block;
            text-decoration: none;
            color: var(--color-main-black);
            position: relative;
            width: 300px;
            min-height: 200px;
            margin-bottom: 28px;
            border: 1px solid var(--color-grey-middle-grids);
            border-radius: 8px;

            &:hover {
                border-color: var(--color-link);
                box-shadow: 0 0 12px var(--color-link);
            }
        }

        &-preview {
            object-fit: cover;
            width: 100%;
            border-radius: 8px;
        }
    }

    &-duration-title {
        font-size: 16px;
        text-decoration: none;
        padding: 5px 24px;
        background: var(--color-stroke-maingray);
        border-radius: 6px;
        width: min-content;
        position: absolute;
        bottom: 8px;
        left: 8px;
        z-index: 100;
    }
}
