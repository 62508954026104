.alert {
    width: 100%;
    max-width: 520px;
    height: max-content;
    padding: 36px 10px 57px;
    background-color: #F9FAFD;
    border: 1px solid rgba(0, 16, 61, 0.12);
    box-sizing: border-box;
    border-radius: 12px;
    position: absolute;
    left: calc(50% - 260px);
    top: 32vh;
    display: flex;
    flex-direction: column;
    align-items: center;

    &_modalWarning {
        width: 440px;
    }

    &-icon {

        path {
            stroke: #EA5959;
        }

        path:last-child {
            fill: #EA5959;
        }
    }

    &-info {
        font: 600 15px/18px 'Source Sans Pro';
        margin-top: 24px;
        text-align: center;
        word-break: break-word;
        color: var(--color-main-black);

        &_modalWarning {
            width: 326px;
        }
    }

    &-descriptions-wrapper {
        font-size: 14px;
        line-height: 1.3;
        margin-top: 16px;
    }

    &-description {
        &_modalWarning {
            text-align: center;
            width: 326px;
        }
    }

    &-extra {
        font-size: 13px;
        line-height: 18px;
        color: var(--color-main-black);

        &-label {
            font-family: Source Sans Pro;
            font-style: normal;
            font-weight: 600;
            font-size: 15px;
            line-height: 18px;
            color: var(--color-main-black);
            text-align: center;
            margin-top: 10px;
        }

        &-wrapper {
            max-height: 252px;
            overflow-y: auto;
            width: 100%;
            padding: 10px 32px 0;

            &::-webkit-scrollbar {
                width: 4px;
                background-color: #EEF4FB;
            }

            &::-webkit-scrollbar-thumb {
                border-radius: 10px;
                background-color: #D1DDEA;
            }

            &::-webkit-scrollbar-track {
                background-color: #EEF4FB;
            }
        }
    }

    &-buttons {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        column-gap: 17px;
        margin-top: 24px;

        button {
            width: 100%;
            padding: 8px 33px;
        }

        &_withoutCancelButton {
            display: block;
        }
    }


    &-close-button {
        border: none;
        background: none;
        outline: none;
        position: absolute;
        top: 16px;
        right: 16px;
        cursor: pointer;
    }

    .button {
        &-action-button {
    
            &_isLastAttempt {
                background-color: var(--color-red-main);
    
                &:hover {
                    background-color: #be3f3f;
                }
    
            }
        }
    }
}

