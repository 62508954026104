.scrollable-container {
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 100%;
    padding-right: 6px;
    scrollbar-gutter: stable;
    scrollbar-width: thin;
    scrollbar-color: var(--schedule-item-indicator-before-border-color);

    /* Работает в Chrome, Edge и Safari */
    &::-webkit-scrollbar {
        width: 4px;
        background-color: #e8edf5;
    }

    &::-webkit-scrollbar-thumb {
        background-color: var(--schedule-item-indicator-before-border-color);
        border-radius: 40px;
    }
}
