.progress-bar {
    display: flex;
    flex-wrap: nowrap;
    width: 100%;

    &.dashed {
        gap: 1px;
    }
    &.solid {
        .progress-bar__cell{
            &:first-of-type {
                .progress-bar__cell_deg{
                    border-radius: 2px 0 0 2px;
                }
            }
            &_deg{
                border-radius: 0;
            }
            &:last-of-type{
                .progress-bar__cell_deg{
                    border-radius: 0 2px 2px 0;
                }
            }

        }
    }

    &__cell {
        display: flex;
        flex-direction: column;
        width: 100%;
        gap: 2px;

        &_deg{
            display: block;
            width: 100%;
            border-radius: 2px;

            &-main{
                background-color: var(--button-border-default);
                height: 4px;

                &.completed {
                    background-color: var(--button-view-action-fill);
                }
                &.expired, &.critical {
                    background-color: var(--color-red-main);
                }
            }

            &-under{
                display: block;
                height: 2px;
                background-color: var(--button-border-default);
            }
        }
    }
}
