.action-block-option {
    &-icon {
        width: 24px !important;
        height: 24px !important;

        &,
        path {
            stroke: var(--color-main-black);
        }

        path:last-child {
            fill: var(--color-main-black);
        }

        &_type {
            &_THEOREM_BLOCK,
            &_QUOTE_BLOCK,
            &_DEFINITION_BLOCK {
                width: 32px !important;
                height: 32px !important;

                &,
                path {
                    stroke: none;
                }
            }
        }
    }
}
