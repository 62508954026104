.groupAvatars {
    position: relative;
    margin-right: 8px;
    display: grid;
    grid-template-columns: 20px 8px 12px 8px 12px 8px 20px;

    &-avatarWrapper {
        position: relative;
        display: inline-block;
        width: 26px;
        height: 26px;
        border-radius: 50%;
        grid-row: 1;

        & .groupAvatars-avatar {
            max-width: 100%;
            height: 100%;
            object-fit: cover;
            border: 1px solid #FFFFFF;
            border-radius: 50%;
        }

        &:first-child {
            grid-column: 1 / 3;
        }

        &:nth-child(2) {
            grid-column: 2 / 5;
        }

        &:nth-child(3) {
            grid-column: 4 / 7;
        }

        &:last-child {
            grid-column: 6 / 8;
        }
    }
}
