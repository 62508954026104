.codifier {
    &-container{
        display: flex;
        flex-direction: column;
    }

    &-skill {
        margin-left: 40px;
    }

    &-empty{
        font-weight: 600;
        font-size: 15px;
        color:#C0C2C9;
        margin-left: 26px;
    }

    &-modalContainer{
        position: fixed;
        left: 0;
        top:0;
        width: 100vw;
        height: 100vh;
        background-color: rgba(39, 39, 39, 0.1);
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 100;
    }
}
