.task-config-container {
    display: flex;
    flex-direction: column;
    gap: 36px;

    &_config {
        display: grid;
        grid-template-columns: minmax(425px, 930px) auto;
        grid-column-gap: 30px;

        .task-config {
            display: flex;
            flex-direction: column;
            gap: 16px;

            &>.app-ui-input .ant-input {
                text-overflow: ellipsis;
                padding: 9px 42px 9px 12px;
            }
            &__main {
                display: grid;
                grid-column-gap: 20px;

                &_type-selector {
                    display: flex;
                    flex-direction: column;
                    gap: 4px;

                    .app-text {
                        color: var(--color-grey-descriptor);
                    }

                }
            }
            &__details_row{
                display: flex;
                align-items: center;
                gap: 2px;

                &>div:first-child {
                    font-weight: bold !important;
                }
            }
        }
        .task-submit {
            display: flex;
            flex-direction: column;
            gap: 22px;

            &__btns {
                display: grid;
                grid-template-columns: 200px 132px;
                gap: 12px;
                align-items: center;
            }
            &__switcher {
                display: flex;
                flex-direction: column;
                gap: 6px;

                &_template {
                    display: grid;
                    grid-template-columns: max-content 32px;
                    align-items: center;
                    gap: 11px;
                }
            }
        }
    }
    &_questions{

    }
}
