.common-drag-layer {
    position: fixed;
    pointer-events: none;
    z-index: 100;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
}
.drag-container {
    opacity: 1;
    height: unset;

    &.isDragging {
        opacity: 0;
        height: 100%;
    }
}
