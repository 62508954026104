.tasks-container{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background-color:#F9FAFD;
    padding-left: 24px;
    padding-top: 20px;
    width: 312px;
    //min-height: 608px;
    height: 100%;
    border: none;
    border-radius: 12px;

    &_disabledSelect {
        width: unset;
    }

    &-tasks{
        overflow-x: hidden;
        overflow-y: auto;
        scroll-behavior: smooth;
        width: 288px;
        min-height: 484px;
        height: 100%;
        margin-top: 34px;

        &::-webkit-scrollbar {
            width: 4px;
            background-color: #eef4fb;
        }

        &::-webkit-scrollbar-thumb {
            border-radius: 10px;
            background-color: #d1ddea;
        }

        &::-webkit-scrollbar-track {
            background-color: #eef4fb;
        }
    }

    .title-name {
        line-height: 35px;
    }
}
