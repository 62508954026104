.tasksTab {
    padding: 20px 0;
    display: flex;
    width: 100%;
    max-height: 100%;
    overflow: hidden;

    &-tasks {
        width: 100%;
        overflow: hidden;
    }

    &-taskCreationFrom {
        margin-right: 36px;
        width: max-content;

        &_withoutGroup {
            margin-right: 0;
        }
    }

    &-tasksHeader {
        display: flex;
        gap: 10px;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 30px;
    }

    &-headerActionButton{
        display: flex;
        gap: 4px;
        align-items: center;

        .button {
            min-width: 180px;
        }
    }
    &-taskAssigningForm {
        width: 100%;
        overflow-x: scroll;
        max-height: calc(100% - 80px);


        &::-webkit-scrollbar {
            width: 4px;
            height: 4px;
            background-color: #EEF4FB;
        }

        &::-webkit-scrollbar-thumb {
            border-radius: 10px;
            background-color: #D1DDEA;
        }

        &::-webkit-scrollbar-track {
            background-color: #EEF4FB;
        }
    }
}
