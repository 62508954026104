.multiple-selector {
    display: flex;
    flex-direction: column;
    gap: 8px;

    .app-select-selection-overflow-item.app-select-selection-overflow-item-rest{
        display: none;
    }
    .app-select-selector {
        padding-left: 12px !important;
    }
}
