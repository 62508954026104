.lessons-file-container {
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100%;

    &__files {
        display: flex;
        flex-direction: column;

        &.isOvered {
            .usage-col {
                display: none;
            }
            .lessons-file-container__files_row {
                grid-template-columns: 1fr .6fr;
            }
        }
        &_row {
            display: grid;
            grid-template-columns: 1fr 0.6fr .6fr;
            align-items: center;
            padding: 12px 0;

            &:not(:nth-child(1)) {
                border-bottom: 1px solid var(--button-border-default);
            }

            .AppTextStyle span {
                white-space: nowrap;
            }
            .access-col, .usage-col {
                justify-self: center;
            }
            .name-col {
                display: grid;
                grid-template-columns: minmax(auto, 400px) max-content;
                justify-content: space-between;
                align-items: center;

                &:hover{
                    .name-col__file-controls {
                        visibility: visible;
                    }
                }
                &__file-title {
                    display: grid;
                    grid-template-columns: auto 1fr;
                    align-items: center;
                    gap: 4px;
                    cursor: pointer;
                    position: relative;

                    &.hasAttr:hover::after {
                        content: attr(data-title);
                        position: absolute;
                        right: 0;
                        top: -100%;
                        transform: translateY(-50%);
                        width: 100%;
                        z-index: 1;
                        background: rgba(243, 243, 243, 0.7);
                        font-size: 11px;
                        padding: 5px 10px;
                        border: 1px solid #bab6b6;
                    }
                }
                &__file-controls {
                    display: flex;
                    align-items: center;
                    gap: 4px;
                    visibility: hidden;

                    &_btn {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        cursor: pointer;
                        width: 40px;
                        height: 40px;
                        border-radius: 4px;

                        &:hover {
                            background-color: var(--color-dashboard);
                        }
                    }
                }
            }
        }
    }
    &__add-file {
        display: flex;
        align-items: center;
        gap: 12px;
        margin-top: 8px;

        &_title {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 8px;
        }
    }
}
