.schedule-tooltip {
    position: absolute;

    &__body{
        position: absolute;
        display: flex;
        background: #FFFFFF;
        transform: translateX(70px) ;
        border-radius: 14px;
        min-width: 150px;
        min-height: 150px;
        z-index: 1000;

        .arrow {
            position: absolute;
            display: block;
            width: 20px;
            height: 20px;
            background-color: #FFFFFF;

            &-left {
                transform: rotate(45deg) translateY(-50%) translateX(-50%);
                left: -10px;
            }
            &-right {
                transform: rotate(45deg) translateY(-50%) translateX(-50%);
                left: calc(100% - 10px);
            }
            &-none {
                display: none;
            }
        }
        &_close-btn {
            cursor: pointer;
            position: absolute;
            height: 22px;
            width: 22px;
            top: 19px;
            right: 19px;
            z-index: 10;

            path {
                stroke: #585C6F;
            }
        }
    }

    &__overlay {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: #2c2d2e;
        opacity: .3;
        z-index: 999;
    }
}
