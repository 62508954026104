.subjects-table-content {
    display: grid;
    grid-template-columns: minmax(200px, 276px) minmax(265px, 344px) minmax(420px, 1fr);
    border-radius: 8px;
    overflow: hidden;
    height: 100%;

    @media (min-width: 1440px){
        grid-template-columns: .9fr .9fr 1fr;
    }
    &.mobile {
        grid-template-columns: 1fr;
        height: unset;

        .subjects-table-content__col.theme-col {
            padding: 20px;
            background-color: var(--color-essential);

            @media (max-width: 768px) {
                padding: 12px 8px ;
            }
        }
    }

    &__col{
        display: flex;
        flex-direction: column;
        gap: 20px;
        background-color: var(--color-essential);
        padding: 16px 4px 16px 16px;
        height: 100%;
        overflow: hidden;

        @media (max-width: 768px) {
            padding: 16px 4px 8px 8px;
        }
        @media (max-width: 768px) {
            padding: 12px 8px;
        }

        &_header{
            display: flex;
            align-items: center;
            justify-content: space-between;

            .switcher {
                display: grid;
                grid-template-columns: auto 32px;
                gap: 8px;
            }
            &-title {
                display: flex;
                align-items: center;
                gap: 12px;

                .app-text:nth-child(2) {
                    color: var(--color-grey-descriptor);
                }
            }
        }
        &.course-col {

            &> *{
                padding-right: 8px;

                @media (max-width: 768px) {
                    padding: 0;
                }
            }
            .subjects-table-content__col_wrapper{

                &-list{
                    gap: 10px;
                }
            }

            .app-collapse {
                display: flex;
                flex-direction: column;
                gap: 10px;
                position: relative;

                &-item-active:last-of-type {
                    &:after {
                        content: '';
                        display: inline-block;
                        position: absolute;
                        left: 0;
                        bottom: 0;
                        right: -8px;
                        height: 1px;
                        background-color: var(--button-border-default);

                        @media (max-width: 768px) {
                            content: none;
                        }
                    }
                }

                &-content-box {
                    padding: 0 0 8px !important;

                }
                &-header {
                    padding: 0 4px 9px 0;
                    cursor: pointer;

                    @media (max-width: 768px) {
                        padding: 0 12px 9px 12px;
                    }
                }
            }
        }
        &.theme-col {
            background-color: var(--color-stroke-maingray);
        }
        &.lesson-col {
            position: relative;

            .add-lesson {
                position: relative;
                width: 100%;
                height: 4px;
                background-color: var(--button-view-action-fill);
                opacity: 0;
                margin: 4px 0;
                cursor: pointer;

                &__button{
                    position: absolute;
                    z-index: 5;
                    left: 50%;
                    transform: translateY(-50%) translateX(-50%);
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 32px;
                    height: 32px;
                    border-radius: 8px;
                    background-color: var(--button-view-action-fill);
                }
                &:hover {
                    opacity: 1;
                    transition: opacity ease-in .10ms;
                }
            }
        }
        &> *{
            padding-right: 12px;
            @media (max-width: 768px) {
                padding-right: 0;
            }
        }
        &_wrapper{
            padding-right: 8px;
            scrollbar-gutter: stable;
            overflow-y: auto;
            height: 100%;

            @media (max-width: 768px) {
                padding-right: 0;
                scrollbar-gutter: unset;
            }

            &-list{
                display: flex;
                flex-direction: column;
                gap: 12px;

                &.with-add-elements {
                    gap: 0;
                    padding-top: 12px;
                }
            }
            &-empty {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 100%;
                min-height: 180px;

                .app-text {
                    color: var(--color-grey-descriptor);
                }
            }

            &::-webkit-scrollbar {
                height: 8px;
                width: 4px;
                scroll-padding-left: 8px;
                background-color: var(--color-stroke-maingray);
            }

            &::-webkit-scrollbar-thumb {
                width: 8px;
                border-radius: 2px;
                background-color: var(--schedule-item-indicator-before-border-color);
            }

            &::-webkit-scrollbar-track {
                background-color: var(--color-stroke-maingray);
            }
        }
    }
    .tabs-loading-container {
        display: flex;
        align-items: center;

        &>div {
            width: 100%;
        }
    }
}
