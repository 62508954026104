.student-task {
    &-form {
        padding-bottom: 4px;
    }

    &-assignment-header {
        margin: 32px 0 28px;
        display: flex;
        flex-wrap: wrap;
        gap: 40px;
    }

    &-assignment-title {
        font-family: "Source Sans Pro";
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 23px;
        margin: 0;
        padding: 0;
    }

    &-assignment-attemptNumber {
        margin: 0;
        padding: 0;
        font-family: Source Sans Pro;
        font-style: normal;
        font-weight: normal;
        font-size: 13px;
        line-height: 20px;
    }

    &-assignment-taskDescription {
        margin: 0;
        padding: 0;
        font-family: Source Sans Pro;
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        line-height: 16px;
        color: var(--color-grey-descriptor);
    }

    &-btn-block {
        margin: 32px 0;
        display: flex;
        align-items: center;
    }

    &-action-btn {
        margin-right: 10px;
    }

    &-no-attempts-message {
        font: 600 18px/20px "Source Sans Pro";
        color: var(--color-main-black);
        margin-right: 36px;
    }

    &-mark {
        margin-left: 12px;

        & div.mark-container .mark-commentText {
            font-family: Source Sans Pro;
            font-style: normal;
            font-weight: 600;
            font-size: 18px;
            line-height: 20px;
            white-space: pre-wrap;
            width: 190px;
        }
    }

    &-is-on-check-label {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        margin-top: 40px;
        padding: 10px 20px;
        width: 408px;
        height: 40px;
        background: var(--color-stroke-maingray);
        border-radius: 6px;
        font: 15px/20px "Source Sans Pro";
        color: var(--color-link);
    }

    &-answer {
        display: flex;
        flex-direction: column;
        gap: 10px;
        margin-top: 40px;

        &:not(:last-child):after {
            content: '';
            display: block;
            width: 100%;
            height: 1px;
            border-bottom: 1px solid var(--schedule-border-color);
        }

        &-question {
            font: 15px/32px "Source Sans Pro";
            color: var(--color-main-black);
        }

        &-options {
            font: 15px/1.2 "Source Sans Pro";
            color: var(--color-main-black);
        }

        .detailed-answer-files > p {
            font: 13px/16px "Source Sans Pro";
            margin: 0;
            color: var(--color-main-dark-icons);
        }

        .file-main > a {
            overflow: hidden;
            text-overflow: ellipsis;
        }

        .test-task-answer-flex {
            flex-direction: column;
            align-items: unset;

            & div:nth-child(2) {
                margin-left: 0;
            }
        }
    }

    &-overdueHomework {
        max-height: 700px;
        height: calc(100vh - 310px);
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;

        &-img {
            object-fit: cover;
            height: calc(100% - 28px);
        }

        &-title {
            margin: 0;
            color: var(--color-text-color);
            font-size: 24px;
            line-height: 28px;
            font-weight: 400;
        }
    }
}

.timer {
    right: 0;
}
