.homeworkTable {
    width: max-content;
    table-layout: fixed;
    border-collapse: collapse;

    &-header {
        font-size: 13px;
        line-height: 16px;
        color: var(--color-grey-middle-grids);
        text-align: left;
    }

    &-studentsHeader {
        &_withAttendance{
            color: var(--schedule-item-text-color);
            opacity: 0.5;
        }
    }

    &-tasksHeader {
        padding-left: 8px;

        &_withAttendance{
            color: var(--schedule-item-text-color);
            opacity: 0.5;
        }
    }

    &-task {
        padding: 0 15px;
        vertical-align: top;
    }

    &-taskTitle {
        font-size: 13px;
        line-height: 20px;
        font-weight: 400;
        color: var(--color-main-black);
        text-align: left;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        max-width: 100px;
    }

    &-assignAllRow {
        border-bottom: 1px solid var(--color-gray-second);
    }

    &-assignForAllStudents {
        display: flex;
        align-items: center;
    }

    &-group {
        display: flex;
        height: 54px;
        align-items: center;
    }

    &-groupLabel {
        font-size: 15px;
        line-height: 20px;
    }

    &-studentCell {
        padding: 10px 0;
        min-width: 240px;

        img {
            border-radius: 50%;
        }
    }

    &_hasSelectAllRow {
        .homeworkTable-studentRow {

            &:nth-child(3) {
                td {
                    padding-top: 16px;
                }
                td:first-child {
                    padding-top: 20px;
                }
            }
        }
    }
}
