.task_block {
    display: flex;
    flex-direction: column;
    align-items: baseline;
    margin-bottom: 20px;

    &-interval {
        padding: 2px 0 0 30px;
    }

    &-teacher{
        display: flex;
        flex-direction: column;
        width: 100%;
        max-width: 234px;
        gap: 4px;
        margin-top: 12px;

        .app-select {
            &-selector{
                border-radius: 4px !important;
                border-color: var(--color-stroke-maingray) !important;

                input {
                    cursor: pointer !important;
                }
            }
            &-selection-placeholder {
                color: var(--color-grey-descriptor) !important;
            }
            &:hover {
                .app-select-selector{
                    cursor: pointer;
                    box-shadow: 0 8px 32px 0 var(--black-shadow-color);
                }
            }
            &-focused {
                .app-select-selector{
                    box-shadow: none !important;
                }
            }

            &-arrow {
                inset-inline-end: 16px;
                path{
                    fill: var(--button-view-action-fill);
                }
            }
            &-clear {
                inset-inline-end: 13px;
            }

        }

    }
    &-form{
        font-size: 13px;
        width: 235px;

        &-block{
            display: flex;
            flex-direction: column;
            align-items: baseline;
            margin-top: 12px;

            .app-select{
                width: 100%;
            }

            .select-options {
                padding: 0;
                max-height: 240px;
            }

            .select-suggest{
                padding-right: 0;
                max-height: 256px;
                border-bottom-right-radius: 5px;
                border-bottom-left-radius: 5px;
            }

            .select-suggest_focus{
                padding-right: 0;
                max-height: 256px;
                padding: 2px 0;
                border-bottom-right-radius: 5px;
                border-bottom-left-radius: 5px;
                background-color: white;
            }
        }

        .calendarDropdown {
            width: 234px;
            margin-top: 8px;
            position: unset;
        }

        .dataSelect-input{
            border-bottom-left-radius: 5px;
            border-top-left-radius: 5px;
        }

        .dateTimePicker{
            z-index: 2;
            padding-bottom: 0;
        }

        span{
            margin-bottom: 4px;
        }
    }
    &-title-buttons {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: 100%;

        &_disabledSelect {
            justify-content: initial;
        }

        &:hover {

            .task_view-title{
                text-decoration: underline;
            }

            .task_view-title_normal{
                text-decoration: underline;
            }

            .task_view-title_template{
                text-decoration: underline;
            }
        }
    }

    &-buttons-block {
        flex: 0 0 40px;
        display: flex;
    }

    button {
        // flex: 0 0 40px;
        border: none;
        background: none;
        padding: 0;
        width: 40px;
        max-height: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }

    &-delete-modal{
        & .ant-modal-content{
           padding: 32px 40px !important;
        }

        &-buttons{
            display: flex;
            flex-direction: row;
            width: 100%;
            gap: 12px;
        }
    }
}
