.task-popup{
    &__breadcrumbs {
        display: flex;
        align-items: baseline;
        gap: 0 16px;
        flex-wrap: wrap;
    }
    &__container {
        display: flex;
        flex-direction: column;
        gap: 24px;
    }
    .ant-drawer-content-wrapper {
        width: 100% !important;
    }
    .ant-drawer-header{
        border-bottom: none;

        &-title {
            display: flex;
            flex-direction: row-reverse;
        }
        .ant-drawer-close {
            margin: 0;
            padding: 0;
        }
    }

    //стили старого контента
    .subject_course_page {
        width: 100%;

        &-main_container {
            margin-top: 48px;
        }

        &-header {
            width: 100%;

            .header-children {
                display: flex;
                align-items: center;
                justify-content: space-between;
                white-space: nowrap;
            }

            .button {
                justify-content: center;
                align-items: center;
                width: 200px !important;

                @media screen and (max-width: 768px) {
                    width: 100px !important;
                }
            }

            .refs {
                display: flex;
                flex-direction: row;
                align-items: center;
                white-space: nowrap;

                .breadcrumbs{
                    margin-left: 24px;
                }

                @media screen and (max-width: 768px) {
                    .breadcrumbs{
                        margin-left: 16px;
                        gap: 4px;
                    }

                    .breadcrumbsItem {
                        visibility: hidden;
                        width: 0;
                        padding: 0;
                        a{
                            width: 0;
                        }
                    }

                    .breadcrumbs {

                        .breadcrumbsItem:not(:first-child){
                            visibility: visible;
                            width: auto;
                        }
                    }
                }
            }

        }

        &-create {
            display: grid;
            grid-template-columns: 1fr auto;
            gap: 12px;

            @media (max-width: 769px) {
                grid-template-columns: auto auto;
            }
        }

        &-title {
            max-width: 664px;
        }

        &-description {
            margin-top: 16px;
            max-width: 664px;
        }

        @media screen and (max-width: 1440px) {
            &-title, &-description {
                width: 100%;
            }
        }

        @media screen and (max-width: 1024px) {
            &-save {
                width: 140px !important;
            }

            &-cancel {
                width: 100px !important;
            }
        }

        &-type_question {
            display:flex;
            gap: 32px;
            margin-bottom: 16px;

            .select-suggest_focus {
                background-color: #fff;
            }

            @media screen and (max-width: 1000px) {
                flex-wrap: wrap;
            }
        }

        &-type_question_details {
            font-size: var(--font-size-medium);
            height: 129px;
        }

        &-type-title {
            font-size: 13px;
            line-height: 16px;
            margin-top:16px;
            color: #919399;
        }

        &-dropdown {
            width:300px;

        }

        &-questions {
            margin-top: 24px;
            display: flex;
            align-items: center;
            margin-bottom: 18px;
        }

        &-question_title {
            font-size: 24px;
            line-height: 24px;
            font-weight: 600;
            color: #252232;
        }

        &-counter_questions {
            margin-left: 40px;
            font-size: 15px;
            line-height: 20px;
            color: #2C2D2E;
        }

        &-buttons {
            display: flex;
        }

        &-save {
            width: 200px;
            margin-right: 12px;
        }

        &-cancel {
            width: 132px;
        }

        &-template {
            display: flex;
            flex-direction: column;
            margin-top: 22px;
        }

        &-template_toggle{
            display: flex;
            flex-direction: row;
            align-items: center;
        }

        &-template_title {
            font-size: 15px;
            line-height:20px;
            color: #2C2D2E;
            margin-right: 11px;
        }

        &-signature {
            margin-top: 6px;
            font-size: 12px;
            line-height:15px;
            color: #919399;
        }
    }
}
