.attendanceMark {
    display: flex;
    align-items: center;
    position: relative;

    &-marks {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        row-gap: 8px;
        max-width: 160px;

        &_statusCard {
            margin-right: 10px;
            padding-right: 8px;
            flex-wrap: nowrap;
            max-width: unset;
        }
    }

    &-dots {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        margin-right: -8px;
        width: 32px;
        height: 32px;
        border-radius: 50%;
        border: 1px solid var(--color-link);
        cursor: pointer;
    }

    &-mark {
        height: 32px;
        border-radius: 50%;

        &_statusCard {
            margin-right: -8px;
            width: 32px;
            border: 1px solid var(--color-essential);
        }
    }

    &-modal {
        display: none;
        position: fixed;
        right: 0;
        overflow: auto;

        &::-webkit-scrollbar {
            width: 4px;
            background-color: var(--color-stroke-maingray);
        }

        &::-webkit-scrollbar-thumb {
            border-radius: 10px;
            background-color: var(--schedule-item-indicator-before-border-color);
        }

        &::-webkit-scrollbar-track {
            background-color: var(--color-stroke-maingray);
        }

        &_absolution {
            position: absolute;
            right: 50%;
            left: -150%;
            top: 40px;
        }
    }

    &-modal_show {
        display: block;
    }

    & textarea {
        padding-top: 10px;
    }

    & .mark {

        &-comment {
            transform: translateY(2px);
        }
    }

    &:hover {
        & .attendanceMark-change {
            display: flex;

            justify-content: center;
            align-items: center;
        }

        & .attendanceMark-change:hover>svg>path {
            stroke: #3AA1EB;
        }

        & .attendanceMark-add {
            display: block;
        }

        & .attendanceMark-add:hover>svg>rect {
            stroke: #3AA1EB;
        }

        & .attendanceMark-add:hover>svg>path {
            fill: #3AA1EB;
        }
    }

    &-form {
        padding: 15px 26px 32px;
        background: #F9FAFD;
        border: 1px solid #EEF4FB;
        box-sizing: border-box;
        box-shadow: 0px 8px 32px rgba(20, 49, 127, 0.08);
        border-radius: 14px;
        max-width: 440px;
        background: #F9FAFD;
        z-index: 1000;

        & .input {
            max-width: 393px;
        }
    }

    &-modalBackground {
        position: fixed;
        left: 0;
        top: 0;
        width: 100vw;
        height: 100vh;
        background: #e9e9e9;
        opacity: .5;
        z-index: 10;
    }

    &-change {
        display: none;

        margin-right: 10px;

        width: 32px;
        height: 32px;
        cursor: pointer;
    }

    &-add {
        display: none;

        width: 32px;
        height: 32px;
        cursor: pointer;

        &_noMarks {
            display: block;
        }
    }
}
