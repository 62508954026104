.add-lesson-modal {
    display: flex;
    flex-direction: column;
    gap: 20px;

    &__wrapper{
        position: absolute !important;

        .ant-modal-content {
            padding: 20px;
        }
    }
    &__lessons {
        display: flex;
        flex-direction: column;
        gap: 8px;
        max-height: 250px;
        min-height: 130px;

        &_card {
            display: flex;
            flex-direction: column;
            gap: 8px;
            border: 1px solid var(--button-border-default);
            border-radius: 8px;
            padding: 12px 20px;
            cursor: pointer;

            .lesson-card {
                &__titles, &__types {
                    display: flex;
                    flex-direction: column;
                    gap: 4px;
                }
                &__types{
                    &_item {
                        display: flex;
                        align-items: center;
                        gap: 8px;
                    }
                    &_loader{
                        display: flex;
                        align-items: center;
                        padding: 0 12px 5px;
                    }

                }
            }


            &.selected {
                background-color: var(--color-link);
                border: 1px solid var(--color-link);
            }
        }
    }
    &__btns {
        display: flex;
        gap: 12px;
    }
}
