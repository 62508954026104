.expanded-question-textarea {
    width: 680px;
    height: fit-content;
    margin-bottom: 20px;
    position: relative;

    textarea {
        width: 100%;
        height: 100%;
        min-height: 68px;
        font-weight: 600;
        font-size: 18px;
        caret-color: var(--color-hover-blue);

        &::placeholder {
            color: var(--color-gray-inactive);
        }
    }
}
