.codifier_dropdown_section {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    &-container{
        display: flex;
        flex-direction: row;
        align-items: center;
        width: fit-content;
        font-size: 15px;
        line-height: 20px;
        padding: 4px 0;
        h4{
            margin: 0;
        }
        &_highlighted {
            padding-left: 20px;
            border-radius: 6px;
            background-color: #EEF4FB;
        }
    }
    &-text {
        font-size: 15px;
        line-height: 20px;
        color: #191919;
        font-weight: 400;
        margin-left: 12px;
        width: fit-content;
        &_highlighted {
            color: #0088E9 !important;
            font-weight: 600;
        }
    }
    &-buttons {
        width: 60px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-around;
        height: 24px;
        position: relative;
        .theme_tooltip {
            z-index: 1;
            top: 36px;
            left: 5px; 
        }
    }
    &-button {
        display: flex;
        background-color: none;
        align-items: center;
        justify-content: center;
        &:hover {
            background-color: #EEF4FB;
        }
        width: 24px;
        height: 24px;
        border: none;
        border-radius: 4px;
        cursor: pointer;
        svg {
            path {
                fill: #0088E9;
            }
        }
    }
    &-header {
        display: flex;
        cursor: pointer;
        flex-direction: row;
        align-items: baseline;
        justify-content: space-between;
        &_disabled {
            cursor: default !important;
            .codifier_dropdown_section-text {
                color: #C0C2C9;
            }
        }
    }
    &-arrow{
        transform: rotate(-90deg);
        padding-bottom: 4px;
        height: 10px;
        path {
            fill: #68788D;
        }
        &_arrow_is_opened {
            transform: rotate(0deg);
            padding-bottom: 2px;
            path {
                fill: #0088E9 !important;
            }
        }
        &_disabled {
            path {
                fill: #C0C2C9;
            }
        }
    }
    &-children_container{
        &_section {
            .codifier_dropdown_section{
                &-container{
                    margin-left: 60px;
                    &_highlighted{
                        margin-left: 40px;
                    }
                }
            }
            .dropdownActionsForm{
                margin-left: 40px !important; 
            }
            .codifier_dropdown_section-children_container_opened {
                .dropdownActionsForm{
                    margin-left: 60px !important; 
                }
            }
        }
        &_opened {
            margin-bottom: 20px;
        }
        .createTheme {
            margin-left: 40px;
            margin-top: 12px;
        }
        .themeCodifier {
            padding: 0px 24px 8px 20px;
            margin-left: 60px;
        }
    }
}
