.app-table{
    display: flex;
    flex-direction: column;
    width: 100%;
    border-left: 1px solid var(--color-gray-second);
    border-top: 1px solid var(--color-gray-second);
    &-row{
        display: flex;
        flex-direction: row;
        width: 100%;
        border-bottom: 1px solid var(--color-gray-second);
    }
}
