.trainer-tab{
    &-student{
        display: flex;
        flex-direction: row;
        gap: 24px
    }

    &_isOld{
        margin-top: 24px;
    }
}
