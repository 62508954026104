.card-wrapper {
    padding: 20px;
    border-radius: 12px;
    background-color: var(--color-essential);
    border: 1px solid var(--color-blue-border);

    &.fetching {
        padding: 0;
        display: grid;
        min-height: 180px;
        overflow: hidden;

    }
    &__skelet-button {
        width: 100% !important;
        height: 100% !important;
    }
}
