.teacher-training-list{
    display: flex;
    flex-direction: column;
    position: relative;
    overflow: hidden;
    gap: 20px;
    padding: 24px 20px;
    background-color: var(--color-essential);
    border: 1px solid var(--color-blue-border);
    border-radius: 20px;

    &-info{
        display: flex;
        flex-direction: row;
        gap: 8px;

        &-text{
            display: flex;
            flex-direction: column;
            gap: 4px;

            &-title{
                font-size: 18px;
                line-height: 20px;
            }
        }
    }

    &-list{
       height: calc(100vh - 310px);
    }

    &-scroll-container{
        & > div {
            display: flex;
            flex-direction: column;
            gap: 12px;
        }

        -ms-overflow-style: none;
        scrollbar-width: none;
        &::-webkit-scrollbar {
            display: none;
        }
    }

    &-loader{
        display: flex;
        width: 100%;
        justify-content: center;
        padding: 10px;
    }

    @-webkit-keyframes rotating /* Safari and Chrome */ {
        from {
            -webkit-transform: rotate(0deg);
            -o-transform: rotate(0deg);
            transform: rotate(0deg);
        }
        to {
            -webkit-transform: rotate(360deg);
            -o-transform: rotate(360deg);
            transform: rotate(360deg);
        }
    }
    @keyframes rotating {
        from {
            -ms-transform: rotate(0deg);
            -moz-transform: rotate(0deg);
            -webkit-transform: rotate(0deg);
            -o-transform: rotate(0deg);
            transform: rotate(0deg);
        }
        to {
            -ms-transform: rotate(360deg);
            -moz-transform: rotate(360deg);
            -webkit-transform: rotate(360deg);
            -o-transform: rotate(360deg);
            transform: rotate(360deg);
        }
    }
    .rotating {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 32px;
        height: 32px;
        -webkit-animation: rotating 2s linear infinite;
        -moz-animation: rotating 2s linear infinite;
        -ms-animation: rotating 2s linear infinite;
        -o-animation: rotating 2s linear infinite;
        animation: rotating 2s linear infinite;
    }
}
