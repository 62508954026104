.link-popup {
    display: flex;
    flex-direction: column;
    padding: 16px 16px 0 18px;
    font-weight: 600;
    font-size: 15px;
    line-height: 20px;

    &-top-row {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-left: 4px;

        .checkbox-label {
            font-size: 13px;
            font-weight: 400;
            line-height: 16px;
            letter-spacing: 0;
        }
    }

    &-head {
        margin: 0;
        font-size: inherit;
        font-weight: inherit;
        line-height: inherit;
    }

    &-input {
        height: 20px;
        padding-bottom: 4px;
        background: none;
        outline: none;
        border: none;
        caret-color: var(--color-link);
        font-size: inherit;
        font-weight: 400;
        line-height: inherit;

        ::placeholder {
            color: var(--color-gray-inactive);
        }

        &_url {
            margin-left: 4px;
            margin-top: 12px;
        }

        &_text {
            margin-left: 8px;
            margin-top: 32px;
            margin-bottom: 24px;
        }

        &_withError {
            border-bottom: 1px solid var(--color-red-main);
        }
    }
}
