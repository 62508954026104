.single-answer-exercise {
    display: flex;
    flex-direction: column;
    gap: 12px;

    &_isCorrect{
        .ant-checkbox-disabled+span {
            color: unset !important;
        }

        .ant-checkbox-disabled .ant-checkbox-inner:after{
            border-color: unset !important;
        }
    }

    &-options{
        display: flex;
        flex-direction: column;
        gap: 12px;

        &-item{
            background-color: var(--color-essential);
            border-radius: 8px;
            border: 1px solid var(--color-blue-border);

            &_type {
                &_incorrect{
                    background-color: var(--color-red-background);
                    border: 1px solid var(--color-red-main);
                }

                &_correct{
                    background-color: var(--color-green-background);
                    border: 1px solid var(--color-green-main);
                }
            }

            & .ant-checkbox-wrapper{
                width: 100%;
                padding: 12px 16px;
            }
        }
    }

    &-label-text{
        font-family: "Source Sans Pro", sans-serif;
        font-size: 15px;
        font-weight: 400;
        line-height: 20px;
        margin: 0;
    }
}
