.exercise-info-modal{
    display: flex;
    flex-direction: column;
    gap: 12px;

    &-author{
        display: flex;
        flex-direction: column;
        gap: 8px;

        &-info{
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 8px;

            &-image{
                width: 32px;
                height: 32px;
                border-radius: 20px;
                background-color: var(--color-grey-descriptor);
            }
        }
    }

    &-tag-list{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 12px;
    }

    &-tag{
        display: flex;
        align-items: center;
        justify-content: center;
        height: 24px;
        padding: 4px 8px;
        border-radius: 100px;
        background-color: var(--color-blue-hover);
    }

    &-status{
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 4px;
    }



    &-loader{
        display: flex;
        flex-direction: column;
        gap: 8px;
    }
    & > .ant-modal-content{
        padding: 16px 20px !important;
    }

    &-question{
        font-weight: 600;
        font-size: 17px;
        line-height: 21.25px;
        font-style: normal;
    }
}
