.open-question {
    &-answer-option {
        width: 100% !important;
        max-width: 892px !important;
        padding-right: 24px !important;
        gap: 12px 36px;
    }

    &-btn-group {
        display: flex;
        justify-content: space-between;
        align-self: flex-end;
        align-items: center;
        // half the (input height - buttons height)
        margin: calc(((1em + 1em + 20px) - 40px) / 2) 0;
        min-width: 164px + 32px;
    }

    &-delete {
        background: none;
        outline: none;
        border: none;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 32px;
        height: 32px;

        svg path {
            stroke: var(--button-view-action-fill);
        }
    }
}
