.question_pool {
    display: flex;
    height: 100vh;
    justify-content: space-between;
    flex-direction: column;
    background-color: var(--color-essential);
    padding: 36px 20px;

    &-head {
        height: 460px;
    }

    &-header {
        font-size: 13px;
        line-height: 20px;
        font-weight: 600;
        color: var(--button-view-action-fill-disabled);
    }

    &-codifier-element-wrapper {
        visibility: visible;

        &_dragging {
            visibility: hidden;
        }

        .codifierElement {
            cursor: pointer;
        }
    }

    &-questions {
        height: 440px;
        .codifierElement {
            margin: 6px 0;
            min-height: 80px;
            padding: 10px 20px;
        }
        &_disabled {
            h4 {
                color: var(--button-view-action-fill-disabled);
            }
            svg {
                path {
                    fill: var(--button-view-action-fill-disabled);
                }
            }
            .codifierElement {
                &:hover {
                    cursor: inherit;
                    box-shadow: none;
                }
            }
        }
    }

    &-comment {
        width: 100%;
        display: flex;
        flex-direction: column;
        border-top: 1px solid var(--color-stroke-maingray);
        height: 20vh;
    }

    &-commentHeader {
        font-weight: 400;
        font-size: 15px;
        line-height: 20px;
        color: var(--color-main-black);
        margin-top: 24px;
    }

    &-commentText {
        font-size: 13px;
        font-weight: 400;
        overflow-y: auto;
        overflow-x: hidden;
        margin-top: 8px;
        color: var(--color-grey-descriptor);
    }

    &-custom-layer-refs {
        &_dragging {
            position: fixed;
            pointer-events: none;
            z-index: 100;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
        }
    }

    &-revision {
        margin-bottom: 12px;
    }
    &-revision-date {
        font-size: 10px;
        color: var(--schedule-item-text-color);
    }

    #scroll-bar::-webkit-scrollbar {
        width: 4px;
        background-color: var(--color-stroke-maingray);
        border-radius: 40px;
    }

    #scroll-bar::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background-color: var(--schedule-item-indicator-before-fill);
        border-radius: 40px;
    }

    #scroll-bar::-webkit-scrollbar-track {
        background-color: var(--color-stroke-maingray);
        border-radius: 40px;
    }
}
