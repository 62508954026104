.latex-option {
    &-container {
        position: relative;
    }

    &-popup-container {
        position: absolute;
        top: 40px;
        left: -20px;
        z-index: 10;
    }

    &-icon {
        path {
            fill: var(--color-main-black);
        }

        &_active {
            path {
                fill: var(--color-link);
            }
        }
    }
}
