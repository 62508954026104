*,
*::after,
*::before {
    box-sizing: border-box;
}

body {
    font-family: 'Source Sans Pro', sans-serif;
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

#root {
  width: 100vw;
  min-height: 100vh;
}

.app-page-container{
    display: flex;
    min-height: 100vh;
}

.app-page-content {
    position: relative;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;

    &:nth-child(2) {
        width: calc(100% - 240px);
        flex-grow: 1;
    }

    &_isMobile{
        &:nth-child(2) {
            width: 100%;
        }
    }
}

.App {
    display: flex;
    overflow-x: hidden;
    overflow-y: auto;
    width: 100%;
    height: 100%;
    background: #f9fafd;
}

