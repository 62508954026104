.infoStatus {
    padding: 8px 16px 0 20px;
    width: 100%;
    border-radius: 12px;
    background-color: #fff;
    box-sizing: border-box;
    cursor: pointer;
    height: 100%;

    &_selected {
        background-color: #F9FAFD;
        border: 1px solid #0088E9;
    }

    &_over.infoStatus_selected {
        background-color: #F9FAFD;
        border: 1px solid #EA5959;
    }

    &-titleMain {
        color: #919399;
        font-size: 15px;
        font-weight: 600;
        line-height: 20px;
        margin-bottom: 1px;
    }

    &-title {
        font-size: 13px;
        line-height: 15px;
        color: var(--color-main-black);

        &_notMain {
            height: 32px;
            margin-top: 5px;
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
        }

        &_extraInfo{
            height: 16px;
        }
    }

    &-status {
        display: flex;
        align-items: center;
        gap: 4px;

        @media screen and (max-width: 1024px) {
            align-items: unset;
        }
    }

    &-extraInfo{
        display: flex;
        flex-direction: row;
        font-weight: 400;
        font-size: 10px;
        line-height: 12px;
        color:var(--schedule-header-subtitle-text-color);
        margin-bottom: 16px;
    }

    &-icon {
        margin-top: 17px;
        margin-bottom: 20px;

        &_mark {
            margin-top: 14px;
            margin-bottom: 13px;
        }
    }

    &-color {
        &_over {
            svg {
                path {
                    stroke: #EA5959;
                }

                circle {
                    fill: #EA5959;
                }
            }
        }
    }

    &-timePassed {
        margin-top: 17px;
        color: var(--color-main-black);
        font-size: 13px;
        line-height: 15px;
        margin-bottom: 20px;
        display: flex;
        align-items: center;
    }

    .tooltip-comment {
        max-height: 100px;
        overflow: auto;

        &::-webkit-scrollbar {
            width: 10px;
            background-color: #eef4fb;
        }

        &::-webkit-scrollbar-thumb {
            border-radius: 10px;
            background-color: #d1ddea;
        }

        &::-webkit-scrollbar-track {
            background-color: #eef4fb;
        }
    }
}
