.table-borderless{

    .app-ui-table-container {
        border: none !important;
    }
    &__row {
        border-radius: 8px;
        overflow: hidden;

        &:nth-child(2n+1) {
            background-color: var(--color-dashboard);
        }
        &:nth-child(2n) {
            background-color: transparent !important;
        }
        td:first-child { border-top-left-radius: 8px; }
        td:last-child { border-top-right-radius: 8px; }

        td:first-child { border-bottom-left-radius: 8px; }
        td:last-child { border-bottom-right-radius: 8px; }

    }
    &__col {
        background: transparent !important;

        .AppTextStyle.DesktopFootNotes{
            font-weight: 600;
            font-size: 15px;

            @media (max-width: 768px) {
                font-size: 13px;
            }
        }
    }

    //768
}
