.library-modal {
    .ant-modal-content {
        padding: 32px;

        @media (max-width: 768px) {
            padding: 52px 16px 16px 16px;
        }

        .ant-modal-close {
            top: 16px !important;
            inset-inline-end: 16px !important;

            &:hover {
                background-color: unset;
                text-decoration: none;
            }

            span.ant-modal-close-x {
                display: flex !important;
                align-items: center;
                justify-content: center;
                width: 20px;
                height: 20px;
                padding: 4px;
            }
        }

    }

    &-content {
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: auto minmax(auto, 460px);
        flex-direction: column;
        gap: 20px;

        &__breadcrumbs{
            display: grid;
            grid-template-columns: auto auto 1fr;
            align-items: baseline;
            gap: 12px;

            div{
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
            }
            div:last-child .app-text{
                color: var(--color-grey-descriptor);
            }
        }
        &__book-list {
            display: flex;
            flex-direction: column;
            gap: 12px;

            &_item {
                display: grid;
                grid-template-columns: auto 1fr;
                align-items: center;
                gap: 12px;
            }
        }
    }

    .book-info {
        display: grid;
        grid-template-columns: 95px 1fr;
        gap: 12px;
        padding: 12px;
        border: 1px solid var(--button-border-default);
        border-radius: 12px;
        cursor: pointer;

        &:hover {
            background-color: var(--color-stroke-maingray);
        }

        &__preview {
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            box-shadow: 0 0 5px 0 var(--color-stroke-maingray);
            height: 132px;
            overflow: hidden;

            img {
                height: 100%;
                width: auto;
            }
        }
        &__content {
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            div {
                display: flex;
                flex-direction: column;
                gap: 2px;

                .app-text:last-child {
                    color: var(--color-grey-descriptor);
                }
            }
        }
    }
}
