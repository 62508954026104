.app-day-schedule {
    display: grid;
    grid-template-columns: 1fr 200px;
    background: #FFFFFF;
    border-top-left-radius: 14px;
    border-bottom-left-radius: 14px;


    &__body{
        position: relative;
        display: grid;
        padding: 24px 0 24px 20px;
        grid-template-columns: minmax(40px, auto) 1fr;

        &_row{
            position: relative;
        }
        &_cards-col {
            position: relative;
            padding: 8px;
            border-top-left-radius: 14px;
            border-bottom-left-radius: 14px;
            overflow-x: auto;
            overflow-y: visible;
            background-image: linear-gradient(to right, #EEF4FB 5px, transparent 1px), linear-gradient(#ccc 1px, transparent 1px);
            background-size: 15px 150px;
            background-position-y: 8px;
            background-color: #EEF4FB;

        }

        .current-time {
            position: absolute;
            display: flex;
            align-items: center;
            width: calc(100% - 18px);
            left: 18px;
            z-index: 100;


            &_label{
                background: #EA5959;
                border-radius: 5px;
                padding: 1px 6px;
                z-index: 101;
                .ant-typography.descriptor{
                    color: #FFFFFF;
                }
            }
            &:after {
                content: '';
                position: absolute;
                display: block;
                width: 100%;
                height: 1px;
                background: #EA5959;
            }
        }
    }
    &__chart {
        position: relative;
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 24px 1fr;
        padding-bottom: 24px;
        border-left: 1px solid #EEF4FB;

        &_title {
            display: flex;
            align-items: center;
            padding-left: 11px;
        }
        &_container {
            position: relative;
            //overflow: hidden;
            display: grid;
            grid-template-columns: 50px 1fr;
            background-color: #FFFFFF;

            &-timeline {
                display: flex;
                flex-direction: column;
                align-items: center;
            }
            &-component {
                background-image: linear-gradient(to right, #FFFFFF 5px, transparent 1px), linear-gradient(#ccc 1px, transparent 1px);
                background-size: 15px 150px;
                overflow: hidden;
                background-position-y: 8px;
            }

        }
        &_chart-container {
            position: absolute;
            width: 100%;
            height: 100%;
            left: 0;
            right: 0;
            top: 8px;
            bottom: 0;
            display: grid;
            grid-template-columns: 1fr;
            grid-template-rows: repeat(auto-fill, 150px);

            .chart-wrapper {
                transform: scale(1.007);

                &.unknown-data:hover:after {
                    content: 'Недостаточно данных';
                    position: absolute;
                    top: 50%;
                    left: 0;
                    right: 0;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    transform: translateY(-50%);
                }
                path {
                    stroke-width: 1;
                }
                g circle {
                    stroke-opacity: 1;
                    stroke: none;
                }
                .google-visualization-tooltip {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding: 10px 30px;
                    border: 1px solid #EEF4FB;
                    box-shadow: 0 8px 32px rgba(20, 49, 127, 0.08);
                    border-radius: 14px;
                    min-width: 85px;
                    transform: translateX(-83%) translateY(10%) !important;

                    &:after{
                        content: '';
                        position: absolute;
                        display: block;
                        width: 15px;
                        height: 15px;
                        background-color: #FFFFFF;
                        top: 100%;
                        left: 50%;
                        border-radius: 3px;
                        transform: rotate(45deg) translateX(-50%) translateY(-20%);
                    }
                    .chart-tooltip-text {
                        font-style: normal;
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 28px;
                    }

                    .chart-tooltip {
                        display: grid;
                        grid-template-columns: 1fr;
                        grid-gap: 16px;

                        &__header {
                            display: flex;
                            align-items: center;
                            flex-wrap: nowrap;
                            justify-content: space-between;

                            &_title{
                                font-style: normal;
                                font-weight: 600;
                                font-size: 17px;
                                line-height: 125%;
                                color: #252232;
                            }
                            &_date{
                                font-style: normal;
                                font-weight: 400;
                                font-size: 15px;
                                line-height: 20px;
                                color: #252232;
                            }
                        }

                    }
                }

            }
        }
        &:hover {
            cursor: pointer;

            .current-time {
                display: flex;
            }
        }
        .current-time {
            position: absolute;
            display: none;
            align-items: center;
            width: calc(100% - 6px);
            left: 6px;
            z-index: 100;


            &_label{
                background: #8F4AB0;
                border-radius: 5px;
                padding: 1px 6px;
                z-index: 101;
                .ant-typography.descriptor{
                    color: #FFFFFF;
                }
            }
        }
    }
}

