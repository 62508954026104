.criteria-collapse {
    &__header {
        display: flex;
        align-items: center;
        gap: 8px;
    }
    &__icon{
        transition: all ease-in-out 0.5ms;
        &.active {
            transform: rotate(90deg);
        }
    }

    .ant-collapse {
        background-color: var(--color-essential);
    }
}
