.app-table-cell{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    border-right: 1px solid var(--color-gray-second);
    height: 40px;
    position: relative;

    &-delete-row-icon{
        position: absolute;
        display: none;
        right: 8px;
    }

    &_isCanHovered,
    &_isEmptyDate{
        &:hover{
            background-color: var(--color-stroke-maingray);
            .app-table-cell-delete-row-icon{
                display: block;
            }
        }
    }

    &_isCanClick{
        cursor: pointer;
    }

    &-add-item-container{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        height: 100%;
        width: 100%;
    }

    &-plus-icon{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        margin-left: 9px;
    }

    &-text-container{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 100%;
        width: 100%;
        &_isDeleteRow{
            padding-right: 22px;
        }
        &_isError{
            border-bottom: 1px solid var(--color-red-main);
        }
    }

    &-title{
        font-size: 13px;
        line-height: 15.6px;
        color: var(--color-main-black);
        text-align: center;

        &_isBold{
            font-weight: 600;
        }

        &_isEmptyDate{
            color: var(--color-link);
        }
    }

    &-subtitle{
        font-size: 13px;
        line-height: 13.6px;
        color: var(--color-grey-descriptor);
        text-align: center;
    }
}
