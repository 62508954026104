.modalWindow{
    position: relative;
    border-radius:20px;
    width: 576px;
    background-color: var(--color-essential);
    display: flex;
    flex-direction: column;
    padding: 62px 20px 20px 20px;
    height: 67vh;

    &-deleteIcon{
        position: absolute;
        top: 22px;
        right: 22px;
        cursor: pointer;
    }

    &-title{
        font-weight: 600;
        font-size: 20px;
        text-align: center;
    }

    &-text{
        font-weight: 400;
        font-size: 15px;
        width: 70%;
        text-align: center;
        align-self: center;
        margin-top: 16px;
    }

    &-elements {
        overflow: hidden auto;
        height: 100%;
        margin: 16px 0 0 0;

        &::-webkit-scrollbar {
            width: 4px;
            height: 8px;
            background-color: var(--color-stroke-maingray);
        }

        &::-webkit-scrollbar-track {
            background: var(--color-stroke-maingray);
            border-radius: 41px;
        }

        &::-webkit-scrollbar-thumb {
            background: var(--color-scroll-bar-background);
            border-radius: 41px;
        }
    }

    &-button{
        size:300px;
        display: flex;
        justify-content: center;
        margin-top: 40px;
    }

    &-topic{
        overflow-y:scroll;

        &::-webkit-scrollbar {
            width: 4px;
            background-color: var(--color-stroke-maingray);
        }

        &::-webkit-scrollbar-thumb {
            border-radius: 10px;
            background-color: var(--schedule-item-indicator-before-border-color);
        }

        &::-webkit-scrollbar-track {
            background-color: var(--color-stroke-maingray);
        }
    }
}
