.assignedHomeworkTable {
    width: 100%;

    &-header {
        display: flex;
        flex-direction: column;
        margin-bottom: 20px;
    }

    &-up-part{
        display: flex;
        align-items: center;
        flex-direction: row;
        margin-bottom: 34px;
    }

    &-homeworkStatus {
        width: 250px;
    }

    &-filterCheckboxes {
        display: flex;
        align-items: center;
        column-gap: 30px;
    }

    &-headerActionButtons {
        margin-left: auto;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        column-gap: 12px;
    }
    &-table-wrapper{
        width: 100%;
        height: 100%;
        overflow: auto;

        .homeworkTable{
            width: unset;
        }
    }
}
