.teacher-working-settings {
    display: flex;
    flex-direction: column;
    gap: 20px;

    &__settings {
        display: grid;
        grid-template-columns: 120px 1fr;
        align-items: center;
        gap: 12px;

        &_counter {
            display: flex;
            flex-direction: column;
            gap: 4px;
        }
        &_custom-reason {
            grid-area: 2/1/3/3;
        }
        .input-icon-container {
            display: none;
        }
        .ant-input-number-group{
            &-addon:has(.control-btn) {
                background-color: var(--color-essential) !important;
                border-color: var(--button-view-action-fill) !important;
                cursor: pointer;
                padding: 6px;

                @media (max-width: 1024px) {
                    padding: 5px;
                }

                &:hover {
                    background-color: var(--button-view-action-fill) !important;
                    color: var(--color-essential);
                }
            }
            &-addon:has(.control-btn.disabled) {
                pointer-events: none;
                cursor: not-allowed;
            }
            &>.ant-input-number {
                border-left: none;
                border-right: none;
                border-color: var(--button-view-action-fill);
            }
            .ant-input-number-input {
                text-align: center;
                padding: 10px 0 !important;

                @media (max-width: 1024px) {
                    padding: 5px 0 !important;
                }
            }
        }
    }

}
