.google-chart-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 400px;

    .google-visualization-tooltip {
        width: 200px !important;
        box-shadow: none;
        border-radius: 8px;
        border-color: var(--button-border-default);
        transform: translateY(-100%);
    }
}
