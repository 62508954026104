.progress-chart {
    position: relative;
    display: grid;
    grid-template-columns: 100px minmax(40px, max-content);
    gap: 4px;

    &__empty {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 32px;
        border-top: 1px solid var(--button-border-default);
        background: linear-gradient(180deg, rgba(211, 224, 239, 0.60) 0%, rgba(211, 224, 239, 0.00) 100%);
    }
    &__legend {
        display: flex;
        flex-direction: column;

        &_item {
            display: flex;
            align-items: baseline;
        }
    }
}
