.timer{
    position: fixed;
    width: 220px;

    @media screen and (max-width: 1024px) {
        width: 160px;
    }

    &-text{
        position: absolute;
        display: flex;
        flex-direction: column;
        align-items: center;
        font-size: 13px;
        font-weight: 400;
        color:var(--color-grey-descriptor);
        top: 50%;
        transform: translateY(-50%);
        left:20px;
        width: 82px;
    }

    &-time{
        font-size: 15px;
        font-weight: 600;
        color:var(--color-main-black);
        text-align: center;

        &_isEnd{
            text-align: center;
        }
    }
}

