.progress-tab {
    display: grid;
    grid-template-columns: 1fr;
    padding: 16px 0;

    &__content-wrapper {
        display: flex;
        flex-direction: column;
        gap: 12px;
    }
    &__table {
        display: grid;
        grid-template-columns: auto 1fr 150px;
        align-items: center;
        gap: 24px;

        &.no-chart {
            grid-template-columns: .8fr 1fr;
        }

        &_chart {
            display: grid;
            grid-template-columns: 1fr;
            max-width: 150px;
        }
        &_name {
            display: grid;
            grid-template-columns: auto 1fr;
            gap: 12px;
            align-items: center;

            &-photo {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 32px;
                height: 32px;
                border-radius: 50%;
                overflow: hidden;
                background-color: #2c2d2e;

                img {
                    height: 100%;
                    width: auto;
                }
            }
        }
    }
}
