.re-auth-modal {
    &-content {
        margin: 0 0 40px;
    }

    &-footer {
        display: flex;
        gap: 20px;
    }
}
