.studentTab {
    display: flex;
    flex-wrap: wrap;

    &-student {
        margin-right: 36px;
        margin-bottom: 16px;
        margin-top: 7px;
    }

    &-title {
        font-size: 15px;
        font-weight: 600;
        line-height: 20px;
        color: var(--color-grey-descriptor);
        margin-bottom: 16px;
        margin-left: 50px;
    }

    &-tabs {
        display: flex;
        overflow-x: auto;
        flex-basis: 440px;
        flex-grow: 1;
    }

    &-tab {
        margin-right: 32px;
        flex: 0 0 200px;
        margin-bottom: 7px;

        &:last-child {
            margin-right: 0;
        }

        @media screen and (max-width: 1024px) {
            flex-basis: 114px;
            margin-right: 24px;
        }
    }

    #scroll-bar::-webkit-scrollbar {
        height: 4px;
        background-color: #EEF4FB;
    }

    #scroll-bar::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background-color: #D1DDEA;
    }
    #scroll-bar::-webkit-scrollbar-track {
        background-color: #EEF4FB;
    }
}
