.app-expandable-text{
    max-width: 100%;
    font-family: Source Sans Pro;

    &__content{
        margin-bottom: unset !important;

        &.blue-mark{
            background: #EEF4FB;
            border: none;
            color: #0088E9;
            padding: 5px 15px;
            border-radius: 6px;
            max-width: max-content;
        }
        &.fontbutton {
            font-style: normal;
            font-weight: 400;
            font-size: 15px;
            line-height: 20px;
        }
        &.footnotes {
            font-style: normal;
            font-weight: 400;
            font-size: 13px;
            line-height: 120%;
        }
        &.fontBody {
            font-style: normal;
            font-weight: 400;
            font-size: 15px;
            line-height: 20px;
        }
        &.main {
            font-style: normal;
            font-weight: 600;
            font-size: 15px;
            line-height: 20px;
        }
        &.H3 {
            font-style: normal;
            font-weight: 600;
            font-size: 17px;
            line-height: 125%;
        }
        &.H2 {
            font-style: normal;
            font-weight: 600;
            font-size: 24px;
            line-height: 28px;
        }
    }
    &__controller{
        color: var(--color-link);
        cursor: pointer;

        &.footnotes {
            font-style: normal;
            font-weight: 400;
            font-size: 13px;
            line-height: 120%;
        }
    }

}
