.latex-popup {
    display: flex;
    flex-direction: column;
    padding: 16px 16px 0 18px;
    font-weight: 600;
    font-size: 15px;
    line-height: 20px;

    &-head {
        margin: 0 0 8px 2px;
        font-size: inherit;
        font-weight: inherit;
        line-height: inherit;
    }

    &-input {
        height: 20px;
        margin-left: 4px;
        background: none;
        outline: none;
        border: none;
        caret-color: var(--color-link);
        font-size: inherit;
        font-weight: 400;
        line-height: inherit;
    }

    &-preview {
        background: #edeef2;
        border-radius: 6px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 20px 16px;
        margin-top: 20px;
        min-height: 60px;
    }
}
