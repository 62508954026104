.question_location_dropdown {
    &-container {
        width: 100%;

        &_isOpened {
            width: 500px;
        }

        textarea {
            resize: none;
            font-size: 15px;
            padding: 10px 12px;
            font-family: "Source Sans Pro", sans-serif !important;
            overflow: hidden;
        }

        .select-together-icon {
            top: 44% !important;
            &_open {
                top: 36% !important;
            }
        }

        .select-together-input {
            height: max-content !important;
        }

        .select-suggest_focus {
            max-height: max-content;
            padding-bottom: 5px;
        }

        .select-option {

            &s {
                max-height: max-content;
            }

            &_focus {
                height: max-content;
                cursor: inherit;
                background-color: #fff;
                padding: 8px;
            }

            &:hover {
                background-color: #fff !important;
            }
        }

        &_isSelected {

            textarea {
                white-space: revert;
            }
        }

        .select_location-opened {

            @media screen and (max-width: 1050px) {
                min-width: unset;
            }
        }
    }

    &-container_children {
        display: flex;
        flex-direction: column;
        background-color: #fff;
        width: 100%;

        h2 {
            font-size: 24px;
            margin-top: 32px;
            margin-bottom: 40px;
        }

        .themeCodifier {
            padding: 8px 24px 8px 20px !important;
        }
    }
}
