.question-search-result {
    display: flex;
    flex-direction: column;
    gap: 24px;
    &__top-bar {
        display: grid;
        grid-template-columns: 1fr max-content;
        grid-gap: 30px;
        justify-content: space-between;
        align-items: flex-start;

        //TODO: согласовать кейс переполнения
        &_questions {
            display: flex;
            width: 100%;
            overflow-x: auto;
            scrollbar-gutter: stable both-edges;
            padding-bottom: 10px;

            &::-webkit-scrollbar {
                height: 2px;
            }

            &::-webkit-scrollbar-track {
                background: var(--color-stroke-maingray);
                border-radius: 41px;
            }

            &::-webkit-scrollbar-thumb {
                background: var(--color-scroll-bar-background);
                border-radius: 41px;
            }
        }
        &_submit {
            display: grid;
            grid-template-columns: 200px 132px;
            gap: 12px;
            align-items: center;
        }
    }

    &__search-result_skelet{
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 12px;
        min-height: 500px;
    }
}
