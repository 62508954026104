.loader {
    display: flex;
    position: relative;
    align-items: center;
    @keyframes rot {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
    &-animation {
        display: flex;
        animation: 2s linear 0s normal none infinite running rot;
    }
    &-logo {
        position: absolute;
        left: 8px;
        top: 2px;
    }
}