.insert-word-popup-option {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &-input {
        background: none;
        border: none;
        outline: none;
        width: 100%;
        margin-right: 16px;
        padding: 0 0 4px 0;
        border-bottom: 1px solid var(--color-stroke-maingray);
        font-size: 13px;
        font-weight: 600;
        line-height: 16px;
        letter-spacing: 0px;
    }

    &-delete {
        outline: none;
        background: none;
        border-radius: 6px;
        border: none;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 32px;
        height: 32px;
        cursor: pointer;
        transition: all linear 100ms;
        border: 1px solid transparent;

        &:hover {
            border: 1px solid var(--color-link);
        }

        svg {
            width: 24px;
            height: 24px;
            path {
                stroke: var(--color-link);
            }
        }
    }
}
