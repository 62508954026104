.trainer-tab-student-content{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 24px;

    &-exercise {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        background-color: var(--background-color);
        border: 1px solid var(--color-blue-border);
        border-radius: 14px;
        padding: 24px;
        gap: 20px;

        &-buttons {
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            & .app-ui-button-container .app-button {
                width: 200px;
            }
        }
    }
}
