.tooltip {
    width: max-content;
    position: relative;
    padding: 10px 24px 15px;
    background: var(--color-essential);
    border: 1px solid var(--color-stroke-maingray);
    border-radius: 10px;
    box-sizing: border-box;
    box-shadow: 0 8px 32px rgba(20, 49, 127, 0.08);
    font-size: 13px;
    line-height: 1.2;
    max-width: 350px;
    word-break: break-word;

    &_error {
        border: 1px solid var(--styled-number-color-red-background) !important;
    }

    &_isList {
        width: 100%;
        max-width: none;
    }

    &.tooltip_view {
        &_comment {
            max-width: 250px;
            padding: 7px 14px 10px;
        }

        &_devices {
            max-width: 178px;
            padding: 8px 14px 9px;
        }

        &_detailed {
            max-width: 255px;
            padding: 7px 14px 10px;
        }

        &_semester {
            max-width: 230px;
            padding: 7px 14px 10px;
            text-align: left;
        }

        &_attendance {
            max-width: 240px;
            padding: 16px 8px 32px 24px;
            text-align: left;
        }
    }

    &-pointer {
        position: absolute;
        width: 12px;
        height: 12px;
        transform: rotate(45deg);
        background-color: var(--color-essential);
        border-bottom: 1px solid var(--color-stroke-maingray);
        border-right: 1px solid var(--color-stroke-maingray);
        box-shadow: 0px 8px 32px rgba(20, 49, 127, 0.08);
        bottom: -7px;

        &_pointerPosition {
            &_center {
                &.tooltip-pointer_pointerSide_top,
                &.tooltip-pointer_pointerSide_bottom {
                    left: calc(50% - 3.5px);
                }
                &.tooltip-pointer_pointerSide_right,
                &.tooltip-pointer_pointerSide_left {
                    top: calc(50% - 3.5px);
                }
            }

            &_left {
                &.tooltip-pointer_pointerSide_top,
                &.tooltip-pointer_pointerSide_bottom {
                    left: 5%;
                }
                &.tooltip-pointer_pointerSide_right,
                &.tooltip-pointer_pointerSide_left {
                    top: 5%;
                }
            }

            &_right {
                &.tooltip-pointer__pointerSide_top,
                &.tooltip-pointer_pointerSide_bottom {
                    right: 5%;
                }
                &.tooltip-pointer_pointerSide_right,
                &.tooltip-pointer_pointerSide_left {
                    bottom: 5%;
                }
            }
        }

        &_pointerSide {
            &_left {
                transform: rotate(135deg);
                left: -7px;
            }

            &_right {
                transform: rotate(-45deg);
                right: -7px;
            }

            &_top {
                transform: rotate(225deg);
                top: -7px;
            }

            &_bottom {
                transform: rotate(45deg);
                bottom: -7px;
            }
        }

        &_error {
            border-bottom: 1px solid var(--styled-number-color-red-background) !important;
            border-right: 1px solid var(--styled-number-color-red-background) !important;
        }
    }

    &-meta-data {
        font-size: 13px;
        font-weight: 700;
    }

    &-footnote {
        font-size: 13px;
        font-style: italic;
        font-weight: 400;
    }

    &-approved {
        font-size: 13px;
        font-weight: 600;
    }

    &-flex {
        display: flex;
        margin-top: 4px;
    }

    &-value-title {
        font-size: 13px;
        font-weight: 400;
    }

    &-value {
        font-size: 13px;
        font-weight: 400;
        margin-left: 3px;
    }

    &-comment {
        font-weight: 400;
    }

    &-attendance-data {
        margin-top: 28px;

        & svg {
            width: 24px;
            height: 24px;
        }
    }

    &-device {
        height: 32px;
        display: flex;
        align-items: center;
        column-gap: 12px;
        margin-bottom: 12px;

        &:last-child {
            margin-bottom: 0;
        }

        &-description {
            color: var(--color-main-black);
            max-width: 150px;
            overflow: hidden;
            word-wrap: break-word;
            white-space: pre-wrap;
        }
    }

    &-comment-label {
        margin-top: 4px;
        font-size: 13px;
        color: var(--color-grey-descriptor);

        &:first-child {
            margin-top: 0px;
        }
    }

    &-files-container {
        margin-top: 4px;

        &:first-child {
            margin-top: 0px;
        }
    }

    &-files-label {
        font-weight: bold;
    }

    &-authorLabel {
        display: flex;
        flex-direction: column;
        font-size: 13px;
        font-weight: 400;
        color: var(--color-grey-descriptor);
        margin-bottom: 8px;
    }

    &-author {
        color: var(--color-main-black);
        font-size: 15px;
    }

    &-files-wrapper {
        display: flex;
        flex-direction: column;
    }

    &-tooltipList {
        display: flex;
        flex-direction: column;
        gap: 10px;

        &-title {
            font-size: 15px;
            line-height: 20px;
            text-align: center;
            color: var(--color-main-black);
            padding-bottom: 6px;
        }

        &-row {
            display: flex;
            flex-direction: row;
            gap: 10px;
            align-items: baseline;
        }

        &-circle {
            min-width: 8px;
            width: 8px;
            height: 8px;
            background: var(--color-stroke-maingray);
            border-radius: 6px;

            &_isValid {
                background: var(--color-green-main);
            }
        }
    }
}
