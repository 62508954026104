.student-tasks-view-popup{
    &__breadcrumbs {
        display: flex;
        align-items: baseline;
        gap: 0 16px;
        flex-wrap: wrap;
    }
    &__container {
        display: flex;
        flex-direction: column;
        gap: 24px;
    }
    .ant-drawer-content-wrapper {
        width: 100% !important;
    }
    .ant-drawer-header{
        border-bottom: none;

        &-title {
            display: flex;
            flex-direction: row-reverse;
        }
        .ant-drawer-close {
            margin: 0;
            padding: 0;
        }
    }

    //стили старого контента
    .student_tasks {
        background-color: var(--color-essential);

        &-header {
            width: 100%;
            margin-bottom: 32px;

            @media screen and (max-width: 1305px) {
                .header-children {
                    width: calc(100vw - 624px);
                }
            }

            @media screen and (max-width: 1024px) {
                margin-bottom: 20px;
            }
        }

        &-container{
            width: 100%;
            overflow-x: auto;
        }

        &-task_container {
            position: relative;
            margin-top: 24px;
            width: 100%;
            height: calc(100vh - 308px);
            padding-right: 108px;
            overflow-y: auto;

            &::-webkit-scrollbar {
                width: 4px;
                background-color: #eef4fb;
            }

            &::-webkit-scrollbar-thumb {
                border-radius: 10px;
                background-color: #d1ddea;
            }

            &::-webkit-scrollbar-track {
                background-color: #eef4fb;
            }
        }
    }

}
