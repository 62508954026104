.option-group-wrapper {
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 4px 4px 4px 12px;
    border: 1px solid var(--color-stroke-maingray);
    border-radius: 6px;
    height: 40px;
    background: white;
}
