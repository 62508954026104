.create-question-popup {

    &__title {
        display: grid;
        grid-template-columns: max-content minmax(auto, 700px);
        align-items: center;
        gap: 30px;

        &_breadcrumbs {
                display: flex;
                align-items: baseline;
                gap: 0 16px;
                flex-wrap: wrap;
        }
    }

    .ant-drawer-content-wrapper {
        width: 100% !important;
    }
    .ant-drawer-header{
        border-bottom: none;

        &-title {
            display: flex;
            flex-direction: row-reverse;
        }
        .ant-drawer-close {
            margin: 0;
            padding: 0;
        }
    }


    //Стили перенесенного компонента
    .question_creation_page {
        display: flex;
        gap: 32px;
        width: 100%;

        &-content {
            display: flex;
            flex-direction: column;
            width: calc(100% - 300px);
            height: calc(100% - 96px);
            padding: 32px;
            border: 1px solid #F9FAFD;
            border-radius: 14px;
        }

        &-questions {
            width: 300px;
            background-color: #fff;
            height: 100%;


            .codifierElement {
                margin: 6px 0;
                min-height: 80px;
                padding: 10px 20px;
            }

            .question_pool {
                padding: 32px 0;
            }
        }

        &-status{
            font-weight: 400;
            font-size: 15px;
            line-height: 20px;
            color:var(--color-red-main);
        }
        &-main_container {
            position: relative;
            min-height: calc(100vh - 138px);
            width: 100%;

            .propertyMenu {
                margin-bottom: 8px;
            }
            h2{
                font-size: 24px;
            }
        }

        &-type_question {
            .expanded-question-delete-card {
                &:hover {
                    cursor: pointer;
                }
            }
        }

        &-question_form_header {
            font-size: 13px;
            font-style: normal;
            font-weight: 600;
            line-height: 16px;
            letter-spacing: 0.01em;
        }

        .question_location_dropdown-container,
        .propertyMenu-location-subject-dropdown,
        .propertyMenu-location {

            width: 100%;
        }

        @media screen and (max-width: 1440px) {
            .question_type-container {
                width: 100%;
                height: 80%;
                svg {
                    width: 100%;
                    height: 100%;
                }
            }

            .expanded-question {
                max-width: none;
                width: 100%;

                .field {
                    width: 90%;
                }

                &-answer-option {
                    width: 100% !important;
                }

                &-input {
                    flex-shrink: 0;
                }

                .center-vertical {
                    width: 100%;
                }
            }
        }

        @media screen and (max-width: 1045px) {
            .propertyMenu-location-subject-dropdown {
                width: 360px
            }

            .question_type-container {
                width: 100%;
                height: 80%;

                svg {
                    width: 100%;
                    height: 100%;
                }
            }


            .question_location_dropdown-container {
                width: 360px;
            }

            &-questions {
                width: 280px;

                .question_pool {
                    padding: 36px 10px;
                }
            }

            &-content {
                padding: 32px 32px 20px 20px;
            }

            .propertyMenu-save {
                width: 160px;
            }

            .codifierElement {
                width: 260px;
            }
        }
        @media screen and (max-width: 1000px) {
            &-main_container {
                min-height: calc(100vh - 198px);
                margin-left: 0;
            }
        }

        @media screen and (max-width: 768px) {
            &-questions {
                width: 268px;

                .question_pool {
                    padding: 36px 10px;

                    &-header {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                    }
                }
            }

            &-content {
                padding: 32px 12px 20px 12px;
            }

            .codifierElement {
                width: 248px;
            }
        }
    }

    .checkbox-input_isError {
        border: 1px solid var(--color-red-main) !important;
    }

}
