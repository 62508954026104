.app-collapse{
    font-family: Source Sans Pro;

    &-item-active {
        .app-collapse-header-text, &>svg path {
            stroke: #0088e9;
            color: #0088e9;
        }
    }
    .app-collapse-content{
        &-box{
            padding: 0 22px !important;
        }
    }
    .app-collapse-arrow{
        transform: rotate(-90deg);
        transition: all 0.2s;
        &.opened {
            transform: rotate(0);
            &>path {
                fill: #0088e9;
            }
        }
    }
}
