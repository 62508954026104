.app-datepicker{
    &-panel {
        position: relative;
        padding-top: 30px;
    }
    &-footer-extra {
        padding: 4px 0 !important;

        .timePickerHeader {
            display: grid;
            grid-template-columns: 1fr 1fr;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            padding: 4px 0;
            border-bottom: 1px solid rgba(5, 5, 5, 0.06);

            &__item {
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
        .timePickerFooter {
            padding: 0 4px;
        }
    }
}

