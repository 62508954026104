.teachers-info-modal {

    .ant-modal-content {
        padding: 40px 32px 32px 32px;

        .ant-modal-close{
            top: 16px !important;
            inset-inline-end: 16px !important;

            &:hover {
                background-color: unset;
                text-decoration: none;
            }
            span.ant-modal-close-x {
                display: flex !important;
                align-items: center;
                justify-content: center;
                width: 20px;
                height: 20px;
                padding: 4px;
            }
        }

    }

    &-content {
        display: flex;
        flex-direction: column;
        gap: 24px;

        &__header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding-bottom: 16px;
            border-bottom: 1px solid var(--button-border-default);
            gap: 10px;

            @media (max-width: 375px) {
                align-items: flex-end;
            }
            &_person {
                display: grid;
                grid-template-columns: auto 1fr;
                align-items: center;
                gap: 10px;

                @media (max-width: 375px) {
                    grid-template-columns: 1fr;
                    align-items: flex-start;
                }

                .teacher-avatar {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 48px;
                    height: 48px;
                    border-radius: 50%;
                    overflow: hidden;

                    @media (max-width: 375px) {
                        width: 100px;
                        height: 100px;
                    }

                    img{
                        width: 100%;
                        height: auto;
                    }
                    .empty-image {
                        background-color: var(--styled-number-color-green-background);
                        width: 100%;
                        height: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        .app-text {
                            color: var(--styled-number-color-green-text);
                            @media (max-width: 375px) {
                                font-size: 42px;
                            }
                        }
                    }
                }
            }
            &_chat {
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 0 10px;
                width: 40px;
                height: 40px;
                border-radius: 50%;
                cursor: pointer;
                border: 1px solid var(--button-view-action-fill);

                svg path {
                    stroke: var(--button-chat-fill);
                }
            }

        }

        &__info {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-column-gap: 20px;
            grid-row-gap: 3px;
            max-height: 400px;
            overflow-y: auto;

            @media (max-width: 475px) {
                grid-template-columns: 1fr;
            }



            &_title .app-text {
                color: var(--schedule-item-indicator-after-border-color);
            }
            &_list {
                display: flex;
                flex-direction: column;
                gap: 4px;
            }
            &-point{
                display:flex;
                flex-direction: column;
                gap: 4px;

                &:nth-last-child(-n+3) {
                    grid-column-start: 1;
                    grid-column-end: 3;

                    @media (max-width: 475px) {
                        grid-column-start: unset;
                        grid-column-end: unset;
                    }
                }

            }

            scrollbar-width: thin;
            scrollbar-color: var(--schedule-item-indicator-before-border-color);

            /* Работает в Chrome, Edge и Safari */
            &::-webkit-scrollbar {
                width: 4px;
            }

            &::-webkit-scrollbar-thumb {
                background-color: var(--schedule-item-indicator-before-border-color);
                border-radius: 40px;
            }
        }
    }


}
