.add-option-buttton {
    background: none;
    outline: none;
    border: none;
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-top: 20px;
    margin-left: -6px;

    svg {
        fill: var(--button-view-action-fill);
    }

    p {
        margin: 0 0 0 8px;
        font-size: 14px;
        color: var(--color-main-black);
    }
}
