.attendanceGarnitureStatus {
    display: flex;
    width: 60px;
    justify-content: space-between;

    & svg {
        width: 20px;
        height: 20px;
        cursor: pointer;
    }

    &-device {
        position: relative;
    }

    &-device:hover {
        display: flex;
        justify-content: center;

        & .tooltip {
            display: block;
        }
    }

    & .tooltip {
        position: absolute;
        display: none;
        top: -40px;
        z-index: 100;
    }
}
