.option-button {
    background: white;
    outline: none;
    border: none;
    border-radius: 6px;
    width: 32px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    margin-right: 8px;
    cursor: pointer;

    &:hover {
        background: var(--color-gray-second);
    }

    svg {
        width: 32px;
        height: 32px;
    }

    &_active {
        background: var(--color-stroke-maingray);
    }
}
