.scroll-paginate-container{
    display: flex;
    width: 100%;
    height: 100%;
    overflow-y: scroll;

    &::-webkit-scrollbar {
        display: none;
    }
}
