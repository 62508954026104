.pageLoader {
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    position: absolute;
    z-index: 100;
    background-color: var(--color-essential);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}