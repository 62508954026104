.mark {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    cursor: pointer;
    flex: 0 0 32px;
    width: 32px;
    height: 32px;
    padding: 0;

    &_status {
        &_accept {
            background: var(--styled-number-color-green-background);
            border-color: var(--color-green-main) !important;

            & .mark-group {
                color: var(--styled-number-color-green-text);
            }

            & .mark-icon {
                & svg {
                    fill: var(--color-green-main);
                }

                &_with_clock path {
                    stroke: var(--color-green-main);
                }
            }

            & .mark-weight,
            & .mark-weight-notApproved {
                background-color: var(--color-green-main);
            }
        }

        &_failed {
            background: var(--styled-number-color-red-background);
            border-color: var(--color-red-main) !important;

            & .mark-group {
                color: var(--styled-number-color-red-text);
            }

            & .mark-icon {
                & svg {
                    fill: var(--color-red-main);
                }

                &_with_clock path {
                    stroke: var(--color-red-main);
                }
            }

            & .mark-weight,
            & .mark-weight-notApproved {
                background-color: var(--color-red-main);
            }
        }

        &_good {
            background: var(--styled-number-color-green-background);
            border-color: var(--color-green-main) !important;

            & .mark-group {
                color: var(--styled-number-color-green-text);
            }

            & .mark-icon {
                & svg {
                    fill: var(--color-green-main);
                }

                &_with_clock path {
                    stroke: var(--color-green-main);
                }
            }

            & .mark-weight,
            & .mark-weight-notApproved {
                background-color: var(--color-green-main);
            }
        }

        &_normal {
            background: var(--styled-number-color-yellow-background);
            border-color: var(--styled-number-color-yellow-text) !important;

            & .mark-group {
                color: var(--styled-number-color-yellow-text);
            }

            & .mark-icon {
                & svg {
                    fill: var(--styled-number-color-yellow-text);
                }

                &_with_clock path {
                    stroke: var(--styled-number-color-yellow-text);
                }
            }

            & .mark-weight,
            & .mark-weight-notApproved {
                background-color: var(--styled-number-color-yellow-text);
            }
        }

        &_bad {
            background: var(--styled-number-color-red-background);
            border-color: var(--color-red-main) !important;

            & .mark-group {
                color: var(--styled-number-color-red-text);
            }

            & .mark-icon {
                & svg {
                    fill: var(--color-red-main);
                }

                &_with_clock path {
                    stroke: var(--color-red-main);
                }
            }

            & .mark-weight,
            & .mark-weight-notApproved {
                background-color: var(--color-red-main);
            }
        }

        &_na {
            background: var(--styled-number-color-red-background);
            border-color: var(--color-red-main) !important;

            & .mark-group {
                color: var(--styled-number-color-red-text);
                font-size: 15px;
            }

            & .mark-icon {
                & svg {
                    fill: var(--color-red-main);
                }

                &_with_clock path {
                    stroke: var(--color-red-main);
                }
            }

            & .mark-weight,
            & .mark-weight-notApproved {
                background-color: var(--color-red-main);
            }
        }

        &_comment {
            background: var(--color-stroke-maingray);
            border-color: var(--color-main-dark-icons) !important;
        }
    }

    &.mark_isNotSelected {
        background: var(--color-gray-second);

        .mark-group.mark-group_isNotSelected {
            color: var(--color-grey-descriptor);
        }

        .mark-group-text.mark-group-text_isNotSelected {
            color: var(--color-grey-descriptor);
        }
    }

    &_notApproved {
        border: 1px dashed;
        background: none !important;
    }

    &-icon {
        position: absolute;
        right: 0px;
        top: -2px;

        & svg {
            width: 10px;
            height: 10px;
        }

        &_with_clock path {
            fill: none;
        }
    }

    &-comment {
        position: absolute;
        top: 8px !important;

        & svg {
            fill: var(--color-main-dark-icons);

            width: 14px;
            height: 13px;
        }
    }

    &-group {
        font-weight: 600;
        font-size: 24px;
        color: var(--color-red-main);
        line-height: 20px;
    }

    &-group-text {
        font-weight: 600;
        font-size: 15px;
        line-height: 20px;
        color: var(--color-red-main);

        &_isAccept {
            color: var(--color-green-main);
        }
    }

    &-tooltip {
        display: none;
        padding-bottom: 16px;

        &-pointer {
            position: absolute;
            width: 12px;
            height: 12px;
            transform: rotate(45deg);
            background-color: var(--color-essential);
            border-bottom: 1px solid var(--color-stroke-maingray);
            border-right: 1px solid var(--color-stroke-maingray);
            box-shadow: 0px 8px 32px rgba(20, 49, 127, 0.08);
            top: -16px;
            z-index: 10000;
        }
    }

    &.mark-tooltip-notApproved {
        display: none;
    }

    &-weight,
    &-weight-notApproved {
        display: none;
    }

    &_hoverable {
        .mark-tooltip,
        .mark-tooltip-notApproved {
            display: flex;
            justify-content: center;
            max-width: 240px;
            position: fixed;
            z-index: 9999;
        }

        .mark-tooltip {
            transform: translate(calc(-50% + 13px), calc(-100% + 7px));
        }

        .mark-tooltip-notApproved {
            transform: translate(calc(-50% + 13px), calc(-100% - 8px));
        }

        .mark-weight,
        .mark-weight-notApproved {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 20px;
            height: 10px;
            position: fixed;
            border-radius: 3px;
            z-index: 9999;

            font-weight: 600;
            font-size: 8px;
            color: var(--color-essential);
            line-height: 8px;
        }

        .mark-weight {
            transform: translate(calc(+30%), calc(280%));
        }
        .mark-weight-notApproved {
            transform: translate(calc(+35%), calc(290%));
        }
    }

    &-container {
        display: flex;
        align-items: center;
        width: 100%;

        .mark-commentText {
            font-size: var(--font-size-small);
            line-height: 16px;
            margin-left: 12px;
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
        }
    }
}
