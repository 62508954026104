.search-result {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 12px;

    &__container {
        display: flex;
        flex-direction: column;
        gap: 24px;
        padding: 25px 20px;
        border-radius: 20px;
        border: 1px solid #EEF4FB;
        background: #FFF;

        &.selected {
            border-color: #D3E0EF;
        }

        &_title {
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 20px;

            .info {
                max-width: 160px;
            }
            .title-with-icon {
                display: flex;
                align-items: center;
                gap: 8px;
            }
        }
    }
}
