.custom-editor-add-insertion-popup {
    position: absolute;
    z-index: 100;
    transform: translate(-50%, 50%);

    &-content {
        width: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 4px;
        background: white;
        border: 1px solid var(--color-link);
        border-radius: 8px;
        color: var(--color-link);
        cursor: pointer;
        position: relative;

        span {
            font-size: 15px;
            font-weight: 400;
            line-height: 20px;
            letter-spacing: 0px;
            background: inherit;
            z-index: 2;
        }

        &::before {
            content: '';
            background: inherit;
            border: inherit;
            border-bottom: none;
            border-right: none;
            width: 26px;
            height: 26px;
            transform: rotate(45deg);
            position: absolute;
            top: -14px;
            left: 36px;
        }
    }
}
