.teacher-training-item{
    display: flex;
    padding: 12px 16px;
    border: 1px solid var(--color-green-main);
    border-radius: 14px;
    cursor: pointer;

    .AppTextStyle>span{

        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
    }
    &-question {
        font-weight: 400;
        font-size: 15px;
        line-height: 20px;
        font-style: normal;

        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
    }
}
