.dropdownActionsForm {
    width: 100%;

    &-codifierValueInputWrapper {
        margin-top: 10px;
    }

    &-flex{
        display: flex;
        align-items: center;
    }

    &-order{
        width: 40px;
        margin-right: 12px;

        .field-form-core-input{
            padding: 0;
            text-align: center;
        }
    }

    &-tit{
        width: 400px;
    }

    &-title{
        font-size: 15px;
        font-weight: 400;
        margin-right: 12px;
    }

    &-menu{
        display: flex;
        align-items: center;
        margin-bottom: 12px;
        .tablet{
            height: 28px;
        }
    }

    &-createTitle{
        font-size: 13px;
        margin-right: 12px;
        font-weight: 600;
        color: var(--color-grey-descriptor);
    }

    &-buttons {
        display: grid;
        margin-top: 18px;
        grid-template-columns: 1.5fr 1fr;
        grid-column-gap: 8px;

        &_isNeedTextField {
            width: 340px;
        }
    }
}
