.criteria-chart {
    display: grid;
    grid-template-columns: max-content 1fr;
    gap: 20px;
    background-color: var(--color-essential);
    padding-top: 12px;

    @media (max-width: 1255px){
        grid-template-columns: 1fr;
    }

    &__legend {
        display: flex;
        flex-direction: column;
        gap: 12px;

        @media (max-width: 1024px){
            flex-direction: row;
            flex-wrap: wrap;
        }
    }
    &__chart-container {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 400px;

        .google-visualization-tooltip {
            width: 200px !important;
            box-shadow: none;
            border-radius: 8px;
            border-color: var(--button-border-default);
        }
    }
    &__tooltip {
        display: flex;
        flex-direction: column;
        gap: 4px;
        width: 100%;
        padding: 12px;


        &_title {
            color: var(--color-grey-descriptor);
        }
        &_row {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
    }
}
