.custom-tooltip {
    display: flex;
    flex-direction: column;
    padding: 12px 14px;
    background-color: var(--color-essential);
    width: 200px;
    border-radius: 8px;
    box-shadow: 0 8px 32px 0 #14317F14;
    border: 1px solid var(--color-blue-border);


    &__data-item {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
}
