.homeworkStatus {
    min-height: 48px;
    padding: 0 16px;
    background: #ffffff;
    border: 1px solid #eef4fb;
    box-sizing: border-box;
    border-radius: 14px;
    display: flex;
    align-items: center;
    width: 100%;

    &:hover {
        box-shadow: 0 0 25px rgba(20, 49, 127, 0.08);
    }

    &_status {
        &_homework {
            border: 1px solid #d1e6e8;
        }

        &_notHomework {
            border-color: transparent;
            background-image: linear-gradient(#fff, #fff), linear-gradient(180deg, #eaeefa 0%, #ff6883 100%);
            background-origin: border-box;
            background-clip: padding-box, border-box;
        }
    }

    &_withoutBorder {
        border: none;
        background: none;
        padding: 0;

        &:hover {
            box-shadow: unset;
        }

        .homeworkStatus-hometask {
            list-style: none;

            li::marker {
                display: none;
            }

            li:before {
                display: none;
            }

            li:before::marker {
                display: none;
            }
        }
    }

    &-icon {
        position: relative;

        &_status {
            &_homework {
                #task {
                    stroke: #2f929a;
                }
            }

            &_notHomework {
                #task {
                    stroke: #ea5959;
                }

                &.homeworkStatus-icon_withoutBorder {
                    #task {
                        stroke: #C0C2C9;
                    }
                }
            }

            &_auto {
                #task {
                    stroke: #252232;
                }
            }

            &_notAuto {
                #task {
                    stroke: #c0c2c9;
                }
            }
        }
    }

    &-info {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    
    &-title {
        margin-left: 8px;
        font-weight: 600;
        font-size: 12px;
        color: #252232;

        &_status {
            &_notAuto {
                color: #c0c2c9;
            }

            &_notHomework {
                &.homeworkStatus-title_withoutBorder {
                    color: #C0C2C9;
                }
            }
        }
    }

    &-hometask {
        margin: 9px 0 9px 11px;
        font-size: 10px;
        color: #68788d;
        list-style: none;
    }

    ul {
        padding: 0;
    }

    li::marker {
        color: #edeef2;
    }

    li:before {
        content: "";
        list-style-type: disc;
        display: list-item;
        float: left;
        width: 1px;
        margin: 0 -5px 0 10px;
        color: #edeef2;
    }

    li:before::marker {
        color: #edeef2;
    }
}
