.themes-col-tabs{
    display: flex;
    align-items: center;
    gap: 8px;

    button.app-button-dropdown__btn{
        width: 100%;
    }
    &__item {
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        border: 1px solid var(--button-border-default);
        border-radius: 6px;
        height: 50px;
        width: 100%;
        min-width: calc(50% - 8px);

        &_loader{
            display: flex;
            align-items: center;
            padding: 0 12px 5px;
        }
        &_person {
            display: flex;
            align-items: center;
            gap: 8px;
            padding: 3px 0;

            &-avatar {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 32px;
                height: 32px;
                border-radius: 50%;
                overflow: hidden;

                img{
                    width: 100%;
                    height: auto;
                }
                .empty-image {
                    background-color: #EEF4FB;
                    width: 100%;
                    height: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    .app-text {
                        color: #0088E9;
                    }
                }
            }
        }
    }
}
