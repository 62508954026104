.app-tab {
    .ant-tabs-tab{
        color: var(--color-grey-descriptor);
        &-active{
            color: var(--color-link);
        }
    }
    .app-text {
        color: inherit;
    }

    .ant-tabs-tab{
        margin: 0 !important;
        padding: 0 10px 10px 10px !important;
    }
}
