.onlineLessonTab {
    height: max-content;
    padding: 20px 0;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;

    @media screen and (max-width: 1400px) {
        justify-content: space-between;
    }

    &__video-list {
        display: grid;
        grid-template-columns: repeat(2, 355px);
        grid-column-gap: 24px;
        grid-row-gap: 45px;

        &_item {
            display: flex;
            flex-direction: column;
            gap: 16px;

            .video-title {
                display: flex;
                align-items: center;
                justify-content: space-between;

                &__switcher {
                    display: grid;
                    grid-template-columns: auto 32px;
                    gap: 8px;
                    align-items: center;
                }
            }
            .video-player {
                position: relative;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                border-radius: 8px;
                border: 2px solid var(--button-border-default);
                overflow: hidden;

                &:hover {
                    border-color: var(--color-link);
                    box-shadow: 0 0 12px var(--color-link);
                }

                &__preview {
                    width: 100%;
                }
                &__duration {
                    padding: 5px 8px;
                    background: rgba(0,0,0,0.6);
                    border-radius: 4px;
                    position: absolute;
                    bottom: 8px;
                    left: 8px;
                    z-index: 100;
                }
                &__play {
                    position: absolute;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 56px;
                    height: 56px;
                    background-color: var(--button-view-action-fill);
                    cursor: pointer;
                    border-radius: 50%;
                }
            }
        }
    }

    &-rooms{
        display: flex;
        flex-wrap: wrap;
        margin: 24px 0 36px;

        .roomCard{
            margin-right: 12px;
            margin-bottom: 12px;
        }

        .roomCard-card{
            width: 244px;
            background-color: var(--color-essential);
        }

        .roomCard-teachers{
            height: 50px;
        }
    }

    &-roomsContainer{
        display: flex;
        flex-direction: column;
    }

    &_isStudent {
        flex-direction: column;
    }

    &-studentMarksTitle {
        font-weight: 600;
        font-size: 24px;
        line-height: 28px;
        margin: 8px 0 14px 0;
    }

    &-studentMarks {
        max-width: 560px;
    }

    &-mark {
        margin-bottom: 20px;

        &:last-child {
            margin-bottom: 0;
        }
    }
}
