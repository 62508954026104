@mixin no-bg {
    background: none;
    outline: none;
    border: none;
}

@mixin center-vertical {
    display: flex;
    align-items: center;
}

.expanded-question {
    box-sizing: border-box;
    width: 100%;
    border: 1px solid #eef4fb;
    border-radius: 12px;
    padding: 24px 32px 40px 32px;
    max-width: 1028px;
    position: relative;
    background: white;

    &-top-row {
        display: flex;
        flex-direction: row-reverse;
        justify-content: flex-end;
        padding-right: 58px;
    }

    &-toggle {
        margin-left: 20px;
        &-wraper {
            span.randorm-label {
                margin-left: 8px;
                color: var(--color-main-black);
                font-size: 13px;
                font-style: normal;
                font-weight: 400;
                line-height: 16px;
                letter-spacing: 0px;
            }
            display: inline-flex;
            align-items: center;
        }
    }

    .field {
        width: 680px;
        height: fit-content;
        margin-bottom: 20px;
        position: relative;

        textarea.input-input {
            width: 100%;
            height: 100%;
            min-height: 68px;
            font-weight: 600;
            font-size: 18px;
            caret-color: var(--color-hover-blue);

            &::placeholder {
                color: var(--color-gray-inactive);
            }
        }
    }

    &-editor-wrapper {
        width: 680px;
        min-height: 120px;

        &_type_insert-words .editor {
            min-height: 120px;
        }
    }

    &-answer-option {
        @include center-vertical();
        justify-content: space-between;
        max-width: 816px;
        padding: 12px 0 12px 12px;
        margin-left: -12px;
        border-radius: 8px;
        transition: 200ms;

        &:hover,
        &:focus,
        &:active,
        &:focus-within {
            background: #f9fafd;
        }
    }

    &-input {
        @include no-bg();
        max-width: 652px;
        width: 100%;
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        border-bottom: 1px solid var(--color-stroke-maingray);
        &_isError {
            border-color: var(--color-red-main);
        }
        &-editor {
            border: none;
        }
    }

    .center-vertical {
        @include center-vertical();
        max-width: 652px;
        width: 100%;
    }

    &-delete-card {
        @include no-bg();
        position: absolute;
        top: 32px;
        right: 24px;
        height: 40px;
        width: 40px;
        padding: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 4px;
        border-color: var(--color-red-main);
        transition: all linear 100ms;
        cursor: pointer;

        svg {
            display: block;
            font-size: 32px;
        }

        &:hover {
            border: 1px solid var(--color-red-main);
        }
    }

    &-binary {
        @include center-vertical();

        &-radio {
            color: var(--color-gray-inactive);
            margin-right: 32px;
        }
    }
}
