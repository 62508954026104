.open-answer-exercise {
    display: flex;
    flex-direction: column;
    gap: 12px;

    &-input{
        &_correctness {
            &_incorrect {
                border-color: var(--color-red-main) !important;
                background-color: var(--color-red-background) !important;
                color: var(--color-main-black) !important;
            }

            &_correct {
                border-color: var(--color-green-main) !important;
                background-color: var(--color-green-background) !important;
                color: var(--color-main-black) !important;
            }
        }
    }

    &-info{
        display: flex;
        flex-direction: column;
    }
}
