.empty-trainer-card{
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 20px;
    border: 1px solid var(--color-blue-border);
    padding: 90px 32px;
    background-color: var(--color-grey-background_1);

    &-text{
        margin-bottom: 20px;
    }

    &-image{
        max-height: 100%;
        max-width: 100%;
        object-fit: cover;
    }
}
