.tree-selector {
    display: flex;
    flex-direction: column;
    gap: 8px;

    .ant-tag {
        margin: 3px;
        padding: 5px;
        height: auto;
        display: flex;
        justify-content: space-between;
        align-items: baseline;
    }
    &__tag-label{
        &_breadcrumbs {
            div {
                display: inline;
                font-weight: 600 !important;
            }

        }
        &>div {
            display: inline;
        }
    }
    .ant-select.ant-tree-select {
        width: 100%;
    }
}
