.draft-time {
    margin-top: 12px;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;

    &-info {
        width: 230px;
        height: 30px;
        background-color: var(--styled-number-color-green-background);
        border-radius: 6px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 15px;
        font-weight: 400;
        color:var(--styled-number-color-green-text);
        margin-right: 12px;
    }

    &-error {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-top: 12px;
        border: 1px solid var(--styled-number-color-green-background);
        border-radius: 6px;
        width: 360px;
        height: 30px;
        font-size: 15px;
        font-weight: 400;
        color:var(--schedule-icon-status-past-color);
    }
}