.codifier_dropdown_grade {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    &-container{
        display: flex;
        flex-direction: row;
        align-items: center;
        width: fit-content;
        font-size: 15px;
        line-height: 20px;
        h4{
            margin: 0;
            margin-left: 8px;
        }
        padding: 4px 0;
        &_highlighted {
            padding-left: 20px;
            border-radius: 6px;
            width: 200px;
            background-color: #EEF4FB;
        }
    }
    &-folders{
        margin-left: 12px;
        &_opened{
            path {
                fill: none !important;
                stroke: #0088E9 !important;
            }
        }
        path {
            fill: none !important;
            stroke: #68788D;
        }
        
        &_disabled {
            path {
                fill: none !important;
                stroke: #C0C2C9;
            }
        }
    }
    &-buttons {
        width: 60px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-around;
        height: 24px;
        position: relative;
        .theme_tooltip {
            z-index: 1;
            top: 36px;
            left: 5px; 
        }
    }
    &-button {
        display: flex;
        background-color: none;
        align-items: center;
        justify-content: center;
        &:hover {
            background-color: #EEF4FB;
        }
        width: 24px;
        height: 24px;
        border: none;
        border-radius: 4px;
        cursor: pointer;
        svg {
            path {
                fill: #0088E9;
            }
        }
    }
    &-header {
        cursor: pointer;
        display: flex;
        flex-direction: row;
        align-items: center;
        &_highlighted {
            color: #0088E9 !important;
        }
        &_disabled {
            cursor: default !important;
            color: #C0C2C9;
        }
    }
    &-arrow{
        transform: rotate(-90deg);
        path {
            fill: #68788D;
        }
        &_arrow_is_opened {
            transform: rotate(0deg);
            path {
                fill: #0088E9 !important;
            }
        }
        
        &_disabled {
            path {
                fill: #C0C2C9;
            }
        }
    }
    &-children_container{
        .codifier_dropdown_section{
            &-container{
                margin-left: 40px;
                &_highlighted{
                    margin-left: 20px;
                }
            }
        }
        &_opened{
            margin-bottom: 20px;
        }
        .dropdownActionsForm{
            margin-left: 40px;
            margin-top: 12px;
        }
        .field{
            width: 444px;
        }
    }
}
