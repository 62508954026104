.app-skeleton{
    width: 100% !important;
    &-container{
        height: 100% !important;
        display: flex;
        flex-direction: column;
    }
    &-item{
        & div{
            height: 100% !important;
            & span{
                height: 100% !important;
                width: 100% !important;
            }
        }
    }
}
