.input {
    height: 40px;
    width: 320px;
    border-radius: 4px;
    padding-left: 12px;
    border: 1px solid rgba(0, 16, 61, 0.12);
    box-sizing: border-box;
    font-size: 15px;
    outline: none;

    &_error {
        padding-left: 12px;
        height: 40px;
        width: 320px;
        border-radius: 4px;
        box-sizing: border-box;
        border: 1px solid #EA5959;
        font-size: 15px;
        outline: none;
    }
}
